import React, { useEffect, useState } from "react";
import { Breadcrumb, Form, Input, Button, Spin, Checkbox } from "antd";
import axios from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useHistory, useParams } from "react-router-dom";
import { addFormData, FetchURL } from "../../../util/help";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

function CreateUser() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const [checked, setChecked] = useState(false);

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const setField = (data) => {
    form.setFieldsValue({
      USER_NO: data.USER_NO,
      USER_PWD: data.USER_PWD,
      USER_NAME: data.USER_NAME,
    });
    setChecked(data.ADMIN_MK === "Y" ? true : false);
  };

  useEffect(() => {
    if (id) {
      showLoading();
      fetch(FetchURL + `system/user/des/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            setField(data.data);
            hideLoading();
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    values.INPUT_USER = localStorage.getItem("USER_NO");
    values.ADMIN_MK = checked ? 'Y' : 'N'
    showLoading();
    if (id) {
      axios.post("system/user/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    } else {
      axios.post("system/user/add", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    }
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Tài Khoản Người Dùng</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Chỉnh Sửa Tài Khoản Người Dùng" : "Tạo Tài Khoản Người Dùng"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
          >
            {/* {id ? (
              <Form.Item label="PNL_NO" name="PNL_NO" hidden>
                <Input />
              </Form.Item>
            ) : null} */}

            <Form.Item label="User No" name="USER_NO">
              <Input />
            </Form.Item>
            <Form.Item label="User Password" name="USER_PWD">
              <Input />
            </Form.Item>
            <Form.Item label="User Name" name="USER_NAME">
              <Input />
            </Form.Item>
            <Form.Item label="Admin Mk" name="ADMIN_MK">
              <Checkbox checked={checked} onChange={()=>setChecked(!checked)}/>
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {id ? "Sửa" : "Tạo mới"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
export default CreateUser;
