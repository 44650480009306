import {
  FETCH_JOB_ORDER_PENDING,
  FETCH_JOB_ORDER_APPROVE,
  APPROVE_THANH_TOAN,
} from "./actionTypes";

export const fetchPendingJobOrder = (pending) => {
  return {
    type: FETCH_JOB_ORDER_PENDING,
    pending,
  };
};

export const fetchApproveJobOrder = (approve) => {
  return {
    type: FETCH_JOB_ORDER_APPROVE,
    approve,
  };
};

export const approveThanhToan = (JOB_NO) => {
  return {
    type: APPROVE_THANH_TOAN,
    JOB_NO,
  };
};