import React from "react";
import {
    FileOutlined,
    DatabaseOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { Link, useHistory } from "react-router-dom";
import { checkUserBD } from "../util/help";

const { SubMenu } = Menu;
export default function NavBarComponent({ routes }) {

    let history = useHistory();

    let user = localStorage.getItem('USER_NO')

    const menuBasicData = [
        {
            name: "Khách Hàng",
            to: "/Dasboard/khachhang",
            exact: true,
        },
        {
            name: "Nhân Viên",
            to: "/Dasboard/nhanvien",
            exact: true,
        },
        {
            name: "Các Loại Chi Phí",
            to: "/Dasboard/chiphi",
            exact: true,
        },
        {
            name: "Hãng Tàu",
            to: "/Dasboard/hangtau",
            exact: true,
        },
        {
            name: "Nhà Xe",
            to: "/Dasboard/nhaxe",
            exact: true,
        },
        {
            name: "Đại Lý",
            to: "/Dasboard/daily",
            exact: true,
        },
        {
            name: "Người dùng",
            to: "/Dasboard/users",
            exact: true,
        },
    ];

    const menuQuanLyHoSo = [
        {
            name: "Tạo Phiếu Theo Dõi",
            to: "/Dasboard/taophieutheodoi",
            exact: true,
        },
        {
            name: "Tạo Job Order",
            to: "/Dasboard/taojoborder",
            exact: true,
        },
        {
            name: "Tạo Job Book Tàu",
            to: "/Dasboard/taojobbooktau",
            exact: true,
        },
        {
            name: "Duyệt Job Order",
            to: "/Dasboard/duyetjoborder",
            exact: true,
        },
        {
            name: "Duyệt Cược Cont",
            to: "/Dasboard/duyetcuoccont",
            exact: true,
        },
        {
            name: "Báo Biểu Hồ Sơ",
            to: "/Dasboard/baobieuhoso",
            exact: true,
        },
    ];

    const menuQuanLyThuChi = [
        {
            name: "Phiếu Chi Tạm Ứng",
            to: "/Dasboard/phieuchitamung",
            exact: true,
        },
        {
            name: "Yêu Cầu Thanh Toán",
            to: "/Dasboard/yeucauthanhtoan",
            exact: true,
        },
        {
            name: "Duyệt Thanh Toán",
            to: "/Dasboard/duyetthanhtoan",
            exact: true,
        },
        {
            name: "Tra Cứu Dữ Liệu",
            to: "/Dasboard/tracuudulieu",
            exact: true,
        },
        {
            name: "Chi Phí Cược Tàu/Cont",
            to: "/Dasboard/chiphicuoctaucont",
            exact: true,
        },
        {
            name: "Phiếu Thu",
            to: "/Dasboard/phieuthu",
            exact: true,
        },
        {
            name: "Báo Biểu Thu Chi",
            to: "/Dasboard/baobieuthuchi",
            exact: true,
        },
    ]

    const menuQuanLyHoSoBD = [
        {
            name: "Tạo Phiếu Theo Dõi",
            to: "/Dasboard/taophieutheodoi",
            exact: true,
        },
        {
            name: "Tạo Job Order",
            to: "/Dasboard/taojoborder",
            exact: true,
        },
        {
            name: "Tạo Job Book Tàu",
            to: "/Dasboard/taojobbooktau",
            exact: true,
        },
        {
            name: "Báo Biểu Hồ Sơ",
            to: "/Dasboard/baobieuhoso",
            exact: true,
        },
    ]

    const menuQuanLyThuChiBD = [
        {
            name: "Phiếu Chi Tạm Ứng",
            to: "/Dasboard/phieuchitamung",
            exact: true,
        },
    ]

    const MenuItem = (menus) => {
        var result = null;
        if (routes.length > 0) {
            result = menus.map((menu) => {
                return (
                    <Menu.Item key={menu.to}>
                        <span>{menu.name}</span>
                        <Link to={menu.to} />
                    </Menu.Item>
                );
            });
        }
        return result;
    };

    return (
        <Menu mode="horizontal" theme='dark'
            defaultSelectedKeys={[history.location.pathname]}>
            {checkUserBD.indexOf(user.toString()) === -1 ? <>
                <SubMenu key="sub1" icon={<DatabaseOutlined />} title="Dữ Liệu Cơ Bản">
                    {MenuItem(menuBasicData)}
                </SubMenu>
                <SubMenu
                    key="sub2"
                    icon={<UnorderedListOutlined />}
                    title="Quản Lý Hồ Sơ"
                >
                    {MenuItem(menuQuanLyHoSo)}
                </SubMenu>
                <SubMenu key="sub3" icon={<FileOutlined />} title="Quản Lý Thu Chi">
                    {MenuItem(menuQuanLyThuChi)}
                </SubMenu>
            </> : <>
                <SubMenu
                    key="sub1"
                    icon={<UnorderedListOutlined />}
                    title="Quản Lý Hồ Sơ"
                >
                    {MenuItem(menuQuanLyHoSoBD)}
                </SubMenu>
                <SubMenu key="sub2" icon={<FileOutlined />} title="Quản Lý Thu Chi">
                    {MenuItem(menuQuanLyThuChiBD)}
                </SubMenu>
            </>}


        </Menu>
    )
}