import {
  FETCH_YEU_CAU_THANH_TOAN,
  DELETE_YEU_CAU_THANH_TOAN,
} from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, JOB_NO) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.JOB_NO === JOB_NO) {
      result = index;
    }
  });
  return result;
};

const yeucauthanhtoan = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_YEU_CAU_THANH_TOAN:
      state = action.yeucau;
      return [...state];
    case DELETE_YEU_CAU_THANH_TOAN:
      index = findIndex(state, action.JOB_NO);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default yeucauthanhtoan;
