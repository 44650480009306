import { Breadcrumb, Button, Col, Form, Row, Select, Spin, Upload } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { actDropdownListPhieuChi } from "../../../actions/actionDropdown";
import PhieuYeuCauThanhToan from "./PhieuYeuCauThanhToan";
import axios from "../../../util/api";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import ThongKeSoJob from "./ThongKeSoJob";
import BaoCaoLoiNhuan from "./BaoCaoLoiNhuan";
import { UploadOutlined } from "@ant-design/icons";
import Axios from "axios";
import ThongKeJobOrder from "./ThongKeJobOrder";
import readXlsxFile from "read-excel-file";

const { Option } = Select;
function BaoBieuThuChi() {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [progress, setProgress] = useState(0);

  const dropdown = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();
  const getListPhieuChi = () => dispatch(actDropdownListPhieuChi());

  const isLoading = useSelector((state) => state.isLoading);

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  useEffect(() => {
    getListPhieuChi();
  }, []);

  function onFinish(values) {
    var formData = new FormData();
    for (var i in values.advanceno) {
      formData.append("advanceno[]", values.advanceno[i]);
    }
    showLoading();
    axios
      .post("print/payment/advance/replenishment-withdrawal-payment", formData)
      .then((res) => {
        hideLoading();
        var newWindow = window.open("", "new window");
        newWindow.document.write(res.data);
      })
      .catch((err) => console.log(err));
  }
  const [form] = Form.useForm();

  function onExcel() {
    var values = form.getFieldsValue();
    var formData = new FormData();
    for (var i in values.advanceno) {
      formData.append("advanceno[]", values.advanceno[i]);
    }
    showLoading();
    axios
      .post("export/payment/advance/replenishment-withdrawal-payment", formData)
      .then((res) => {
        hideLoading();
        console.log(res.data);
        window.open(res.data.url);
      })
      .catch((err) => console.log(err));
  }

  const schema = {
    LENDER: {
      prop: "LENDER",
      type: String,
    },
  };

  const uploadFile = (options) => {
    // const { onSuccess, onError, file, onProgress } = options;
    // console.log(options);
    var formData = new FormData();
    // formData.append("file", file);
    var formData = new FormData();
    readXlsxFile(options.target.files[0], { schema }).then(
      ({ rows, error }) => {
        for (var i in rows) {
          formData.append("advanceno[]", rows[i].LENDER);
        }

        showLoading();
        axios
          .post(
            "export/payment/advance/replenishment-withdrawal-payment",
            formData
          )
          .then((res) => {
            hideLoading();
            window.open(res.data.url);
          })
          .catch((err) => console.log(err));
      }
    );
  };

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    //Using Hooks to update the state to the current filelist
    setDefaultFileList(fileList);
    //filelist - [{uid: "-1",url:'Some url to image'}]
  };

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Báo Biểu Thu Chi</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>
            Thống Kê Phiếu Bù và Phiếu Trả
          </Title>
        </div>
        <Spin spinning={isLoading}>
          <Form onFinish={onFinish} form={form} size="small">
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="advanceno">
                  <Select mode="tags" placeholder="Chọn">
                    {dropdown.listPhieuChi.map((item) => {
                      return (
                        <Option key={item.LENDER_NO}>{`${item.LENDER_NO} | ${
                          item.JOB_NO ? item.JOB_NO : ""
                        } | ${item.LENDER_NAME}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  In
                </Button>
                <Button
                  type="primary"
                  onClick={() => onExcel()}
                  style={{ marginLeft: 20 }}
                >
                  Xuất excel
                </Button>
                {/* <Upload
                  customRequest={uploadFile}
                  onChange={handleOnChange}
                  defaultFileList={defaultFileList}
                >
                  <Button style={{ marginLeft: 20 }} icon={<UploadOutlined />}>
                    Gửi file excel lên
                  </Button>
                </Upload> */}
              </Col>
              <Col>
                <input type={"file"} onChange={(input) => uploadFile(input)} />
              </Col>
            </Row>
          </Form>
        </Spin>
        <PhieuYeuCauThanhToan />
        <ThongKeSoJob />
        <BaoCaoLoiNhuan />
        <ThongKeJobOrder />
      </div>
    </Fragment>
  );
}

export default BaoBieuThuChi;
