import React, { useEffect } from "react";
import { Breadcrumb, Form, Input, Button, Spin } from "antd";
import axios from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useHistory, useParams } from "react-router-dom";
import { addFormData, FetchURL } from "../../../util/help";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

function CreateTypeCost() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const setField = (data) => {
    form.setFieldsValue({
      DESCRIPTION_CODE: data.DESCRIPTION_CODE,
      DESCRIPTION_NAME_VN: data.DESCRIPTION_NAME_VN,
      DESCRIPTION_NAME_CN: data.DESCRIPTION_NAME_CN,
    });
  };

  useEffect(() => {
    if (id) {
      showLoading();
      fetch(FetchURL + `data-basic/type-cost/des/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            setField(data.data);
            hideLoading();
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    showLoading();
    if (id) {
      axios.post("data-basic/type-cost/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    } else {
      axios.post("data-basic/type-cost/add", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    }
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Chi Phí</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Chỉnh Sửa Chi Phí" : "Tạo Chi Phí Mới"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
          >
            {id ? (
              <Form.Item
                label="DESCRIPTION_CODE"
                name="DESCRIPTION_CODE"
                hidden
              >
                <Input />
              </Form.Item>
            ) : null}

            <Form.Item label="Tiếng Việt" name="DESCRIPTION_NAME_VN">
              <Input />
            </Form.Item>
            <Form.Item label="Tiếng Hoa" name="DESCRIPTION_NAME_CN">
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {id ? "Sửa" : "Tạo mới"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
export default CreateTypeCost;
