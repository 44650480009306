import { DELETE_STAFF, FETCH_STAFF } from "./actionTypes";

export const fetchStaff = (staff) => {
  return {
    type: FETCH_STAFF,
    staff,
  };
};

export const deleteStaff = (PNL_NO) => {
  return {
    type: DELETE_STAFF,
    PNL_NO,
  };
};
