import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin, Table, Button, Popconfirm, Pagination } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import {
  addFormData,
  FetchURL,
  openNotificationWithIcon,
} from "../../../util/help";
import axios from "../../../util/api";
import {
  fetchJobOrder,
  deleteJobOrder,
} from "../../../actions/actionTaoJobOrder";
import SearchComponent from "../../../Component/SearchComponent";

function TaoJobBookTau() {
  const [state, setState] = useState({ total_page: 1 });
  const [search, setSearch] = useState({ type: "", keyword: "" });

  const isLoading = useSelector((state) => state.isLoading);
  const taojoborder = useSelector((state) => state.taojoborder);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const Fetch = (data) => dispatch(fetchJobOrder(data));
  const DeletePhieuTheoDoi = (JOB_NO) => dispatch(deleteJobOrder(JOB_NO));

  useEffect(() => {
    onChangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.keyword]);

  const onChangePage = (page) => {
    var newPage = 1;
    var url = "";
    if (page) {
      newPage = page;
    }
    if (search.keyword) {
      url = `file/job-order/search/type=${search.type}&value=${search.keyword}&page=${newPage}`;
    } else {
      url = `file/job-order/page=${newPage}`;
    }
    showLoading();
    fetch(FetchURL + url)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const Delete = (JOB_NO, CUST_NAME) => {
    var params = {
      JOB_NO: JOB_NO,
    };
    showLoading();
    axios
      .post("file/job-order/remove", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            DeletePhieuTheoDoi(JOB_NO);
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã xóa thành công ${CUST_NAME}`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Tên Khách Hàng",
      dataIndex: "CUST_NAME",
      key: "CUST_NAME",
    },
    {
      title: "Người Nhập",
      dataIndex: "INPUT_USER",
      key: "INPUT_USER",
    },
    {
      title: "Người Sửa",
      dataIndex: "MODIFY_USER",
      key: "MODIFY_USER",
    },
    {
      render: (text, record) => (
        <>
          <Link to={`/Dasboard/taojobbooktau/edit/${record.JOB_NO}`}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#faad14",
                borderColor: "#faad14",
                marginRight: 20,
              }}
              icon={<EditOutlined />}
            >
              <span style={{ marginLeft: 8, color: "white" }}>Sửa</span>
            </Button>
          </Link>
          {(localStorage.getItem("USER_NO") === "PC                  ") |
          (localStorage.getItem("USER_NO") === "QUYNH               ") |
          (localStorage.getItem("USER_NO") === "JENNY               ") ? (
            <Popconfirm
              title="Bạn có chắn chắn xóa?"
              onConfirm={() => Delete(record.JOB_NO, record.CUST_NAME)}
            >
              <Button type="primary" danger icon={<DeleteOutlined />}>
                Xóa
              </Button>
            </Popconfirm>
          ) : null}
        </>
      ),
    },
  ];
  function onSearch(values) {
    setSearch({
      type: values.type,
      keyword: values.keyword,
    });
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Tạo Job Book Tàu</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Tạo Job Book Tàu</Title>
        </div>
        <SearchComponent onSearch={onSearch} name="joborder" />
        <Button type="primary" style={{ marginBottom: 20 }}>
          <Link to="/Dasboard/taojobbooktau/create">Tạo mới</Link>
        </Button>

        <Table
          dataSource={taojoborder}
          columns={columns}
          rowKey={columns[0].key.toString()}
          pagination={false}
          size="small"
        />
        <Pagination
          total={state.total_page}
          defaultCurrent={1}
          onChange={onChangePage}
          style={{ marginTop: 20 }}
        />
      </div>
    </Spin>
  );
}

export default TaoJobBookTau;
