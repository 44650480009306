import LayoutCustom from "./Layout/LayoutCustom";
import CreateCustomer from "./Page/BasicData/Customer/CreateCustomer";
import Customer from "./Page/BasicData/Customer/Customer";
import Staffs from "./Page/BasicData/Staffs/Staffs";
import Login from "./Page/Login/Login";
import CreateStaff from "./Page/BasicData/Staffs/CreateStaff";
import TypeCosts from "./Page/BasicData/TypeCosts/TypeCosts";
import CreateTypeCost from "./Page/BasicData/TypeCosts/CreateTypeCost";
import Garages from "./Page/BasicData/Garages/Garages";
import Carriers from "./Page/BasicData/Carriers/Carriers";
import Agents from "./Page/BasicData/Agents/Agents";
import CreateCarrier from "./Page/BasicData/Carriers/CreateCarrier";
import CreateGarage from "./Page/BasicData/Garages/CreateGarage";
import CreateAgents from "./Page/BasicData/Agents/CreateAgents";
import TaoPhieuTheoDoi from "./Page/QuanLyHoSo/TaoPhieuTheoDoi/TaoPhieuTheoDoi";
import CreatePhieuTheoDoi from "./Page/QuanLyHoSo/TaoPhieuTheoDoi/CreatePhieuTheoDoi";
import TaoJobOrder from "./Page/QuanLyHoSo/TaoJobOrder/TaoJobOrder";
import CreateJobOrder from "./Page/QuanLyHoSo/TaoJobOrder/CreateJobOrder";
import PhieuChiTamUng from "./Page/QuanLyThuChi/PhieuChiTamUng/PhieuChiTamUng/PhieuChiTamUng";
import CreatePhieuChiTamUng from "./Page/QuanLyThuChi/PhieuChiTamUng/PhieuChiTamUng/CreatPhieuChiTamUng";
import TaoJobBookTau from "./Page/QuanLyHoSo/TaoJobBokTau/TaoJobBookTau";
import CreateJobBookTau from "./Page/QuanLyHoSo/TaoJobBokTau/CreateJobBookTau";
import YeuCauThanhToan from "./Page/QuanLyThuChi/PhieuChiTamUng/YeuCauThanhToan/YeuCauThanhToan";
import CreateYeuCauThanhToan from "./Page/QuanLyThuChi/PhieuChiTamUng/YeuCauThanhToan/CreateYeuCauThanhToan";
import DuyetJobOrder from "./Page/QuanLyHoSo/DuyetJobOrder/DuyetJobOrder";
import BaoBieuHoSo from "./Page/QuanLyHoSo/BaoBieuHoSo/BaoBieuHoSo";
import DuyetThoanToan from "./Page/QuanLyThuChi/DuyetThanhToan/DuyetThanhToan";
import TraCuuDuLieu from "./Page/QuanLyThuChi/TraCuuDuLieu/TraCuuDuLieu";
import ChiPhiCuocTauCont from "./Page/QuanLyThuChi/ChiPhiCuocTauCont/ChiPhiCuocTauCont";
import ChiPhiCuocTauContEdit from "./Page/QuanLyThuChi/ChiPhiCuocTauCont/ChiPhiCuocTauContEdit";
import PhieuThu from "./Page/QuanLyThuChi/PhieuThu/PhieuThu";
import PhieuThuCreate from "./Page/QuanLyThuChi/PhieuThu/PhieuThuCreate";
import BaoBieuThuChi from "./Page/QuanLyThuChi/BaoBieuThuChi/BaoBieuThuChi";
import Users from "./Page/BasicData/Users/Users";
import CreateUser from "./Page/BasicData/Users/CreateUser";
import DuyetCuocCont from "./Page/QuanLyHoSo/DuyetCuocCont/DuyetCuocCont";

const routes = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: "/Dasboard",
    exact: false,
    component: LayoutCustom,
    routes: [
      {
        path: "/Dasboard/khachhang",
        exact: true,
        component: Customer,
      },
      {
        path: "/Dasboard/khachhang/create",
        exact: true,
        component: CreateCustomer,
      },
      {
        path: "/Dasboard/khachhang/edit/:id",
        exact: true,
        component: CreateCustomer,
      },
      {
        path: "/Dasboard/users",
        exact: true,
        component: Users,
      },
      {
        path: "/Dasboard/users/create",
        exact: true,
        component: CreateUser,
      },
      {
        path: "/Dasboard/users/edit/:id",
        exact: true,
        component: CreateUser,
      },
      {
        path: "/Dasboard/nhanvien",
        exact: true,
        component: Staffs,
      },
      {
        path: "/Dasboard/nhanvien/create",
        exact: true,
        component: CreateStaff,
      },
      {
        path: "/Dasboard/nhanvien/edit/:id",
        exact: true,
        component: CreateStaff,
      },
      {
        path: "/Dasboard/chiphi",
        exact: true,
        component: TypeCosts,
      },
      {
        path: "/Dasboard/chiphi/create",
        exact: true,
        component: CreateTypeCost,
      },
      {
        path: "/Dasboard/chiphi/edit/:id",
        exact: true,
        component: CreateTypeCost,
      },
      {
        path: "/Dasboard/hangtau",
        exact: true,
        component: Carriers,
      },
      {
        path: "/Dasboard/hangtau/create",
        exact: true,
        component: CreateCarrier,
      },
      {
        path: "/Dasboard/hangtau/edit/:id",
        exact: true,
        component: CreateCarrier,
      },
      {
        path: "/Dasboard/nhaxe",
        exact: true,
        component: Garages,
      },
      {
        path: "/Dasboard/nhaxe/create",
        exact: true,
        component: CreateGarage,
      },
      {
        path: "/Dasboard/nhaxe/edit/:id",
        exact: true,
        component: CreateGarage,
      },
      {
        path: "/Dasboard/daily",
        exact: true,
        component: Agents,
      },
      {
        path: "/Dasboard/daily/create",
        exact: true,
        component: CreateAgents,
      },
      {
        path: "/Dasboard/daily/edit/:id",
        exact: true,
        component: CreateAgents,
      },
      {
        path: "/Dasboard/taophieutheodoi",
        exact: true,
        component: TaoPhieuTheoDoi,
      },
      {
        path: "/Dasboard/taophieutheodoi/create",
        exact: true,
        component: CreatePhieuTheoDoi,
      },
      {
        path: "/Dasboard/taophieutheodoi/edit/:id",
        exact: true,
        component: CreatePhieuTheoDoi,
      },
      {
        path: "/Dasboard/taojoborder",
        exact: true,
        component: TaoJobOrder,
      },
      {
        path: "/Dasboard/taojoborder/create",
        exact: true,
        component: CreateJobOrder,
      },
      {
        path: "/Dasboard/taojoborder/edit/:id",
        exact: true,
        component: CreateJobOrder,
      },
      {
        path: "/Dasboard/taojobbooktau",
        exact: true,
        component: TaoJobBookTau,
      },
      {
        path: "/Dasboard/taojobbooktau/create",
        exact: true,
        component: CreateJobBookTau,
      },
      {
        path: "/Dasboard/taojobbooktau/edit/:id",
        exact: true,
        component: CreateJobBookTau,
      },
      {
        path: "/Dasboard/phieuchitamung",
        exact: true,
        component: PhieuChiTamUng,
      },
      {
        path: "/Dasboard/phieuchitamung/create",
        exact: true,
        component: CreatePhieuChiTamUng,
      },
      {
        path: "/Dasboard/phieuchitamung/edit/:id",
        exact: true,
        component: CreatePhieuChiTamUng,
      },
      {
        path: "/Dasboard/yeucauthanhtoan",
        exact: true,
        component: YeuCauThanhToan,
      },
      {
        path: "/Dasboard/yeucauthanhtoan/edit/:id",
        exact: true,
        component: CreateYeuCauThanhToan,
      },
      {
        path: "/Dasboard/yeucauthanhtoan/create",
        exact: true,
        component: CreateYeuCauThanhToan,
      },
      {
        path: "/Dasboard/yeucauthanhtoan/edit/:id",
        exact: true,
        component: CreateYeuCauThanhToan,
      },
      {
        path: "/Dasboard/duyetjoborder",
        exact: true,
        component: DuyetJobOrder,
      },
      {
        path: "/Dasboard/duyetcuoccont",
        exact: true,
        component: DuyetCuocCont,
      },
      {
        path: "/Dasboard/baobieuhoso",
        exact: true,
        component: BaoBieuHoSo,
      },
      {
        path: "/Dasboard/duyetthanhtoan",
        exact: true,
        component: DuyetThoanToan,
      },
      {
        path: "/Dasboard/tracuudulieu",
        exact: true,
        component: TraCuuDuLieu,
      },
      {
        path: "/Dasboard/chiphicuoctaucont",
        exact: true,
        component: ChiPhiCuocTauCont,
      },
      ,
      {
        path: "/Dasboard/chiphicuoctaucont/edit/:id&:month",
        exact: true,
        component: ChiPhiCuocTauContEdit,
      },
      {
        path: "/Dasboard/phieuthu",
        exact: true,
        component: PhieuThu,
      },
      ,
      {
        path: "/Dasboard/phieuthu/create",
        exact: true,
        component: PhieuThuCreate,
      },
      {
        path: "/Dasboard/phieuthu/edit/:id",
        exact: true,
        component: PhieuThuCreate,
      },
      ,
      {
        path: "/Dasboard/baobieuthuchi",
        exact: true,
        component: BaoBieuThuChi,
      },
    ],
  },
];

export default routes;
