const {
  DROP_DOWN_CUSTOMER,
  DROP_DOWN_STAFF,
  DROP_DOWN_LIST_NOT_CREATED_ORDER,
  DROP_DOWN_LIST_JOB_PHIEU_CHI,
  DROP_DOWN_LIST_PAYMENT_NOT_CREATE,
  DROP_DOWN_AGENT,
  DROP_DOWN_CARRIER,
  DROP_DOWN_TYPE_COSTS,
  DROP_DOWN_JOB_ORDER,
  LIST_OF_CUSTOMER,
  DROP_DOWN_INFORMATION_USER,
  DROP_DOWN_PAYMENT_DEBIT,
  DROP_DOWN_LIST_PHIEU_CHI,
  DROP_DOWN_LIST_JOB_HAS_D,
} = require("../actions/actionTypes");

var initialState = {
  customer: [],
  staff: [],
  jobNotCreatedOrder: [],
  jobPhieuChiTamUng: [],
  jobPaymentNotCreate: [],
  agent: [],
  carrier: [],
  costs: [],
  jobOrder: [],
  listOfCustomer: [],
  information: [],
  paymentDebit: [],
  listPhieuChi: [],
  listJobHasDebit: []
};

const dropdown = (state = initialState, action) => {
  switch (action.type) {
    case DROP_DOWN_CUSTOMER:
      state.customer = action.customer;
      return { ...state };
    case DROP_DOWN_STAFF:
      state.staff = action.staff;
      return { ...state };
    case DROP_DOWN_LIST_NOT_CREATED_ORDER:
      state.jobNotCreatedOrder = action.jobNotCreatedOrder;
      return { ...state };
    case DROP_DOWN_LIST_JOB_PHIEU_CHI:
      state.jobPhieuChiTamUng = action.job;
      return { ...state };
    case DROP_DOWN_LIST_PAYMENT_NOT_CREATE:
      state.jobPaymentNotCreate = action.job;
      return { ...state };
    case DROP_DOWN_AGENT:
      state.agent = action.agent;
      return { ...state };
    case DROP_DOWN_CARRIER:
      state.carrier = action.carrie;
      return { ...state };
    case DROP_DOWN_TYPE_COSTS:
      state.costs = action.costs;
      return { ...state };
    case DROP_DOWN_JOB_ORDER:
      state.jobOrder = action.joborder;
      return { ...state };
    case LIST_OF_CUSTOMER:
      state.listOfCustomer = action.list;
      return { ...state };
    case DROP_DOWN_INFORMATION_USER:
      state.information = action.information;
      return { ...state };
    case DROP_DOWN_PAYMENT_DEBIT:
      state.paymentDebit = action.paymentDebit;
      return { ...state };
    case DROP_DOWN_LIST_PHIEU_CHI:
      state.listPhieuChi = action.data;
      return { ...state };
      case DROP_DOWN_LIST_JOB_HAS_D:
      state.listJobHasDebit = action.data;
      return { ...state };
    default:
      return state;
  }
};

export default dropdown;
