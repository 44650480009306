import React, { useEffect } from "react";
import { Breadcrumb, Form, Input, Button, Spin, Select, Row, Col } from "antd";
import axios from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useHistory, useParams } from "react-router-dom";
import {
  actDropdownCustomer,
  actDropdownStaff,
} from "../../../actions/actionDropdown";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  handleEnter,
} from "../../../util/help";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const { Option } = Select;

function CreatePhieuTheoDoi() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const isLoading = useSelector((state) => state.isLoading);
  const dropdown = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const getDropDownCustomer = () => dispatch(actDropdownCustomer());
  const getDropDownStaff = () => dispatch(actDropdownStaff());

  const setField = (data) => {
    form.setFieldsValue({
      JOB_NO: data.JOB_NO,
      CUST_NO: data.CUST_NO,
      NV_CHUNGTU: data.NV_CHUNGTU,
      NV_GIAONHAN: data.NV_GIAONHAN,
      ORDER_FROM: data.ORDER_FROM,
      ORDER_TO: data.ORDER_TO,
      CONTAINER_QTY: data.CONTAINER_QTY,
      ETA_ETD: convertDateTime(data.ETA_ETD),
      NW: data.NW,
      GW: data.GW,
      CONTAINER_NO: data.CONTAINER_NO,
      CUSTOMS_NO: data.CUSTOMS_NO,
      CUSTOMS_DATE: convertDateTime(data.CUSTOMS_DATE),
      BILL_NO: data.BILL_NO,
      INVOICE_NO: data.INVOICE_NO,
      NOTE: data.NOTE,
      POL: data.POL,
      POD: data.POD,
      INPUT_USER: data.INPUT_USER,
      INPUT_DT: data.INPUT_DT,
    });
  };

  useEffect(() => {
    getDropDownCustomer();
    getDropDownStaff();
    if (id) {
      showLoading();
      fetch(FetchURL + `file/job-start/des/${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            setField(data.data);
            hideLoading();
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    showLoading();
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    if (id) {
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      axios.post("file/job-start/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            // history.goBack();
          }
        }
      });
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios.post("file/job-start/add", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            // history.goBack();
            history.push(
              `/Dasboard/taophieutheodoi/edit/${res.data.data.JOB_NO}`
            );
          }
        }
      });
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Hồ Sơ</Breadcrumb.Item>
        <Breadcrumb.Item>Tạo Phiếu Theo Dõi</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? `Chỉnh Sửa Phiếu Theo Dõi / ${id}` : "Tạo Phiếu Theo Dõi Mới"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
            onKeyDown={onKeyDown}
            size='small'
          >
            {id ? (
              <Form.Item label="JOB_NO" name="JOB_NO" hidden>
                <Input />
              </Form.Item>
            ) : null}
            <Row>
              <Col span={12}>
                <Form.Item label="Mã khách hàng" name="CUST_NO">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                   onKeyDown={handleEnter}
                  >
                    {dropdown.customer.map((customer) => {
                      return (
                        <Option key={customer.CUST_NO}>
                          {customer.CUST_NO + " | " + customer.CUST_NAME}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Nhân viên chứng từ" name="NV_CHUNGTU">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onKeyDown={handleEnter}
                  >
                    {dropdown.staff.map((staff) => {
                      return (
                        <Option key={staff.PNL_NO}>
                          {staff.PNL_NO + " | " + staff.PNL_NAME}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Nhân viên giao nhận" name="NV_GIAONHAN">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onKeyDown={handleEnter}
                  >
                    {dropdown.staff.map((staff) => {
                      return (
                        <Option key={staff.PNL_NO}>
                          {staff.PNL_NO + " | " + staff.PNL_NAME}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Order from" name="ORDER_FROM">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Order to" name="ORDER_TO">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Container qty" name="CONTAINER_QTY">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="ETA/ETD" name="ETA_ETD">
                  <Input type="date" onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="GW" name="GW">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="NW" name="NW">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Container No" name="CONTAINER_NO">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Customs No" name="CUSTOMS_NO">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Ngày Tờ Khai" name="CUSTOMS_DATE">
                  <Input type="date" onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Bill No" name="BILL_NO">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Invoice No" name="INVOICE_NO">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="Note" name="NOTE">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="POL" name="POL">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
                <Form.Item label="POD" name="POD">
                  <Input onKeyDown={handleEnter}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Người Nhập" name="INPUT_USER">
                  <Input disabled style={{ color: "red" }} onKeyDown={handleEnter}/>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Ngày Nhập" name="INPUT_DT">
                  <Input disabled style={{ color: "red" }} onKeyDown={handleEnter}/>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Người Sửa" name="MODIFY_USER">
                  <Input disabled style={{ color: "red" }} onKeyDown={handleEnter}/>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Ngày Sửa" name="MODIFY_DT">
                  <Input disabled style={{ color: "red" }} onKeyDown={handleEnter}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                  <Button accessKey="s" type="primary" htmlType="submit">
                    {id ? "Lưu" : "Tạo mới"}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                {id ? (
                  <Button
                    type="primary"
                    style={{ marginLeft: 20 }}
                    onClick={() =>
                      history.push("/Dasboard/taophieutheodoi/create")
                    }
                  >
                    Tạo mới
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
export default CreatePhieuTheoDoi;
