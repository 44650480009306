const { DETAIL_CUSTOMER, CLEAR_DETAIL } = require("../actions/actionTypes");

var initialState = {
  customer: {},
};

const isItemDetail = (state = initialState, action) => {
  switch (action.type) {
    case DETAIL_CUSTOMER:
      state.customer = action.customer;
      return {...state};
    case CLEAR_DETAIL:
      state = {};
      return state;
    default:
      return state ;
  }
};

export default isItemDetail;
