import React from "react";
// import { Layout } from "antd";
import "./App.css";
import "antd/dist/antd.css";
// import SiderComponent from "./Component/SiderComponent";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "./routes";
import { RouterWithSubRoutes } from "./RouterWithSubRoutes";

function App() {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => {
          return <RouterWithSubRoutes key={i} {...route} />;
        })}
      </Switch>
    </Router>
  );
}

export default App;
