import React, { Fragment, useEffect } from "react";
import Title from "antd/lib/typography/Title";
import { Col, Row, Select, Input, Button, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  actDropdownAgent,
  actDropdownCarrier,
} from "../../../actions/actionDropdown";
import axios from "../../../util/api";
import { addFormData } from "../../../util/help";
import Axios from "axios";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";

const { Option } = Select;

function InRefund() {
  const dispatch = useDispatch();
  const dropdown = useSelector((state) => state.dropdown);

  const FetchCarrie = () => dispatch(actDropdownCarrier());
  const FetchAgent = () => dispatch(actDropdownAgent());
  const [form] = Form.useForm();

  useEffect(() => {
    FetchAgent();
    FetchCarrie();
  }, []);

  function onFinishJobOrder(values) {
    var from = undefined;
    var to = undefined;
    if (values.FROM_DATE && values.TO_DATE) {
      var yearFrom = values.FROM_DATE.slice(0, 4);
      var monthFrom = values.FROM_DATE.slice(5, 7);
      var dayFrom = values.FROM_DATE.slice(8, 10);

      var yearTo = values.TO_DATE.slice(0, 4);
      var monthTo = values.TO_DATE.slice(5, 7);
      var dayTo = values.TO_DATE.slice(8, 10);

      from = yearFrom + monthFrom + dayFrom;
      to = yearTo + monthTo + dayTo;
    }

    window.open(
      `https://hp-api.ihtvn.com/api/v1/print/file/refund?type=${values.SELECT}&custno=${values.CUST_NO}&jobno=${values.JOB_NO}&fromdate=${from}&todate=${to}`
    );
  }

  function onExcel() {
    var values = form.getFieldsValue();
    var from = undefined;
    var to = undefined;
    if (values.FROM_DATE && values.TO_DATE) {
      var yearFrom = values.FROM_DATE.slice(0, 4);
      var monthFrom = values.FROM_DATE.slice(5, 7);
      var dayFrom = values.FROM_DATE.slice(8, 10);

      var yearTo = values.TO_DATE.slice(0, 4);
      var monthTo = values.TO_DATE.slice(5, 7);
      var dayTo = values.TO_DATE.slice(8, 10);

      from = yearFrom + monthFrom + dayFrom;
      to = yearTo + monthTo + dayTo;
    }
    var params = {
      type: values.SELECT,
      custno: values.CUST_NO,
      jobno: values.JOB_NO,
      fromdate: from,
      todate: to,
    };
    axios
      .post("export/file/refund", addFormData(params))
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          window.open(res.data.url);
        }
      })
      .catch((err) => console.log(err));
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const key = "updatable";
  function exportExcel() {
    message.loading({ content: "Loading...", key });
    var values = form.getFieldsValue();
    var from = undefined;
    var to = undefined;
    if (values.FROM_DATE && values.TO_DATE) {
      var yearFrom = values.FROM_DATE.slice(0, 4);
      var monthFrom = values.FROM_DATE.slice(5, 7);
      var dayFrom = values.FROM_DATE.slice(8, 10);

      var yearTo = values.TO_DATE.slice(0, 4);
      var monthTo = values.TO_DATE.slice(5, 7);
      var dayTo = values.TO_DATE.slice(8, 10);

      from = yearFrom + monthFrom + dayFrom;
      to = yearTo + monthTo + dayTo;
    }

    var params = {
      CUST_NO: values.CUST_NO,
      ORDER_TYPE:
        values.SELECT === "1" ? "5" : values.SELECT === "2" ? "6" : "7",
      CUST_TYPE:
        values.SELECT === "1" ? "2" : values.SELECT === "2" ? "1" : "3",
      FROM_DATE: from,
      TO_DATE: to,
      BRANCH_ID: "IHTVN1",
    };
    Axios.post(
      "https://ihtnode.herokuapp.com/api/reports-basic/excel-refund",
      params
    )
      .then((res) => {
        exportToCSV(
          res.data.data,
          `refund-${values.CUST_NO} ${values.FROM_DATE}&${values.TO_DATE}`
        );
        message.success({ content: "Done!", key, duration: 2 });
      })
      .catch((err) => console.log(err));
  }

  const schema = {
    CUST_NO: {
      // JSON object property name.
      prop: "CUST_NO",
      type: String,
    },
    CUST_NAME: {
      // JSON object property name.
      prop: "CUST_NAME",
      type: String,
    },
    JOB_NO: {
      // JSON object property name.
      prop: "JOB_NO",
      type: String,
    },
    ORDER_DATE: {
      // JSON object property name.
      prop: "ORDER_DATE",
      type: String,
    },
    BILL_NO: {
      // JSON object property name.
      prop: "BILL_NO",
      type: String,
    },
    DESCRIPTION: {
      // JSON object property name.
      prop: "DESCRIPTION",
      type: String,
    },
    THANH_TOAN_MK: {
      // JSON object property name.
      prop: "THANH_TOAN_MK",
      type: String,
    },
    UNIT: {
      // JSON object property name.
      prop: "UNIT",
      type: String,
    },
    QTY: {
      // JSON object property name.
      prop: "QTY",
      type: String,
    },
    PRICE: {
      // JSON object property name.
      prop: "PRICE",
      type: String,
    },
    SAU_THUE: {
      // JSON object property name.
      prop: "SAU_THUE",
      type: String,
    },
    TONG_TIEN: {
      // JSON object property name.
      prop: "TONG_TIEN",
      type: String,
    },
    ETD_ETA: {
      // JSON object property name.
      prop: "ETD_ETA",
      type: String,
    },
    ID: {
      // JSON object property name.
      prop: "ID",
      type: String,
    },
  };

  const readTest = (input) => {
    message.loading({ content: "Loading...", key });
    readXlsxFile(input.target.files[0], { schema }).then(({ rows, errors }) => {
      Axios.post(
        "https://ihtnode.herokuapp.com/api/reports-basic/edit-excel-refund",
        rows
      )
        .then((res) => {
          message.success({ content: "Done!", key, duration: 2 });
        })
        .catch((err) => console.log(err));
    });
  };

  return (
    <Fragment>
      <Title level={4} style={{ color: "#1890ff" }}>
        Báo Biểu Refund
      </Title>
      <Form onFinish={onFinishJobOrder} form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Loại" name="SELECT">
              <Select>
                <Option key="1">Refund Hãng Tàu</Option>
                <Option key="2">Refund Khách Hàng</Option>
                <Option key="3">Refund Đại Lý</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.SELECT !== currentValues.SELECT
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("SELECT") === "1" ? (
                  <Form.Item label="Hãng Tàu" name="CUST_NO">
                    <Select
                      placeholder="Chọn Hãng Tàu"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dropdown.carrier.map((item, index) => {
                        return (
                          <Option key={index} value={item.CUST_NO}>
                            {item.CUST_NO + " | " + item.CUST_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.SELECT !== currentValues.SELECT
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("SELECT") === "2" ? (
                  <Form.Item label="Mã Khách Hàng" name="CUST_NO">
                    <Select
                      placeholder="Chọn Mã Khách Hàng"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dropdown.customer.map((item, index) => {
                        return (
                          <Option key={index} value={item.CUST_NO}>
                            {item.CUST_NO + " | " + item.CUST_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.SELECT !== currentValues.SELECT
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("SELECT") === "3" ? (
                  <Form.Item label="Đại Lý" name="CUST_NO">
                    <Select
                      placeholder="Chọn Đại Lý"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dropdown.agent.map((item, index) => {
                        return (
                          <Option key={index} value={item.CUST_NO}>
                            {item.CUST_NO + " | " + item.CUST_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item label="Job No" name="JOB_NO">
              <Select
                placeholder="Danh Sách Job"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {dropdown.jobOrder.map((item, index) => {
                  return (
                    <Option key={index} value={item.JOB_NO}>
                      {item.JOB_NO + "  |  " + item.CUST_NAME}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="From Date" name="FROM_DATE">
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="To Date" name="TO_DATE">
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          style={{ marginLeft: 10, width: 100 }}
        >
          Xem
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: 10, width: 100 }}
          onClick={() => onExcel()}
        >
          Excel
        </Button>
        {localStorage.getItem("USER_NO") === "QUYNH               " ? (
          <Button
            type="default"
            style={{ marginLeft: 10 }}
            onClick={() => exportExcel()}
          >
            Export Excel For Edit Refund
          </Button>
        ) : null}

        {localStorage.getItem("USER_NO") === "QUYNH               " ? (
          <input
            style={{ marginLeft: 10, marginTop: 20 }}
            type="file"
            onChange={(input) => readTest(input)}
          />
        ) : null}
      </Form>
    </Fragment>
  );
}

export default InRefund;
