import {
  FETCH_JOB_ORDER,
  DELETE_JOB_ORDER,
  CREATE_JOB_ORDER,
} from "./actionTypes";

export const fetchJobOrder = (job) => {
  return {
    type: FETCH_JOB_ORDER,
    job,
  };
};

export const deleteJobOrder = (JOB_NO) => {
  return {
    type: DELETE_JOB_ORDER,
    JOB_NO,
  };
};

export const createJobOrder = (job) => {
  return {
    type: CREATE_JOB_ORDER,
    job,
  };
};
