import React, { Fragment, useState } from "react";
import { Form, Row, Col, Select, Button } from "antd";
import Title from "antd/lib/typography/Title";
import { useSelector } from "react-redux";

const { Option } = Select;

function InPhieuTheoDoi() {
  const dropdown = useSelector((state) => state.dropdown);
  const [dataDrop, setDataDrop] = useState([]);

  function onFinish(values) {
    window.open(
      `https://hp-api.ihtvn.com/api/v1/print/file/job-start/fromjob=${values.JOB_NO}&tojob=${values.JOB_NO2}`
    );
  }
  const onSearch = (input) => {
    fetch(
      `https://hp-api.ihtvn.com/api/v1/file/job-start/search/type=job_no&value=${
        input ? input : "22"
      }&page=1`
    )
      .then((res) => res.json())
      .then((data) => {
        setDataDrop(data.data);
      });
  };

  return (
    <Fragment>
      <Title level={4} style={{ color: "#1890ff" }}>
        In Phiếu Theo Dõi
      </Title>
      <Form onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={10}>
            <Form.Item label="From" name="JOB_NO">
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(input) => {
                  setTimeout(onSearch(input), 5000);
                }}
              >
                {dataDrop.map((item, index) => {
                  return (
                    <Option key={index} value={item.JOB_NO}>
                      {item.JOB_NO}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="To" name="JOB_NO2">
            <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(input) => {
                  setTimeout(onSearch(input), 5000);
                }}
              >
                {dataDrop.map((item, index) => {
                  return (
                    <Option key={index} value={item.JOB_NO}>
                      {item.JOB_NO}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: 10, width: 100 }}
            >
              Xem
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}

export default InPhieuTheoDoi;
