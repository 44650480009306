import React, { Fragment, useEffect, useState } from "react";
import {
  Breadcrumb,
  Form,
  Input,
  Spin,
  Select,
  Row,
  Col,
  Button,
  Checkbox,
  Collapse,
  Table,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  actDropdownAgent,
  actDropdownCarrier,
  actDropdownListPaymentNotCreate,
} from "../../../../actions/actionDropdown";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  handleEnter,
} from "../../../../util/help";
import {
  actHideLoading,
  actShowLoading,
} from "../../../../actions/actionLoading";
import axios from "../../../../util/api";
import CreateItemYeuCauThanhToan from "./CreateItemYeuCauThanhToan";

const { Option } = Select;

function CreateYeuCauThanhToan() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const [job_d, setJob_d] = useState([]);
  const [lender, setLender] = useState("");

  const isLoading = useSelector((state) => state.isLoading);
  const dropdown = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const getDropDownAgent = () => dispatch(actDropdownAgent());
  const getDropDownCarrier = () => dispatch(actDropdownCarrier());
  const getDropDownListJobPPaymentNoteCreate = () =>
    dispatch(actDropdownListPaymentNotCreate());

  const [dataTest, setDataTest] = useState([]);

  const columnSearch = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
  ];

  const setField = (data) => {
    form.setFieldsValue({
      JOB_NO: data.JOB_NO,
      CUST_NAME: data.CUST_NAME,
      PAYMENT_CHK: data.PAYMENT_CHK === "N" ? false : true,
      PAYMENT_DATE: convertDateTime(data.PAYMENT_DATE),
      CUST_NO: data.CUST_NO,
      BILL_NO: data.BILL_NO,
      ORDER_DATE: convertDateTime(data.ORDER_DATE),
      ORDER_FROM: id ? data.TRANS_FROM : data.ORDER_FROM,
      ORDER_TO: id ? data.TRANS_TO : data.ORDER_TO,
      CUSTOMS_NO: data.CUSTOMS_NO,
      CUSTOMS_DATE: convertDateTime(data.CUSTOMS_DATE),
      NW: data.NW,
      GW: data.GW,
      POL: data.POL,
      POD: data.POD,
      ETD_ETA: convertDateTime(data.ETD_ETA),
      CONTAINER_QTY: data.CONTAINER_QTY,
      CONTAINER_NO: data.CONTAINER_NO,
      INVOICE_NO: data.INVOICE_NO,
      NOTE: data.NOTE,
      CONSIGNEE: data.CONSIGNEE,
      SHIPPER: data.SHIPPER,
      PO_NO: data.PO_NO,
      DEBIT_DATE: convertDateTime(data.DEBIT_DATE),
      QTY: data.QTY,
    });
  };

  function onChangeEdit(value) {
    showLoading();
    fetch(FetchURL + `file/job-order/des/job=${value}&type=JOB_ORDER_BOAT`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setJob_d(data.job_d);
          setField(data.data);
          history.push(`/Dasboard/taojobbooktau/edit/${value}`);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  }

  const getDataDetail = (id) => {
    fetch(FetchURL + `payment/debit-note/des/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setJob_d(data.debit_note_d);
          setField(data.debit_note_m);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch(FetchURL + "payment/debit-note/take=50")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setDataTest(data.data);
          // Fetch(data.data);
          // setState({ total_page: data.total_page });
          // hideLoading();
        }
      })
      .catch((err) => console.log(err));
    getDropDownCarrier();
    getDropDownAgent();
    getDropDownListJobPPaymentNoteCreate();
    if (id) {
      showLoading();
      getDataDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    showLoading();
    values.TRANS_FROM = values.ORDER_FROM;
    values.TRANS_TO = values.ORDER_TO;
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    if (id) {
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      axios.post("payment/debit-note/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
          }
        }
      });
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios.post("payment/debit-note/add", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            setLender();
            setField(res.data.data);
            hideLoading();
            history.push(
              `/Dasboard/yeucauthanhtoan/edit/${res.data.data.JOB_NO}`
            );
          }
        }
      });
    }
  };

  const onCahngSelectJob = (values) => {
    showLoading();
    fetch(FetchURL + `payment/debit-note/des-job-not-created/${values}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const [search, setSearch] = useState("");

  function onChange(value) {
    var url = "";
    if (value) {
      url = `payment/debit-note/search/type=${
        search ? search : "job_no"
      }&value=${value}`;
    } else {
      url = "payment/debit-note/take=50";
    }
    showLoading();
    fetch(FetchURL + url)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          onChangeEdit(data.data[0].JOB_NO);
          setDataTest(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  }

  function onChangeEdit(value) {
    showLoading();
    fetch(FetchURL + `payment/debit-note/des/${value}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setJob_d(data.debit_note_d);
          setField(data.debit_note_m);
          history.push(`/Dasboard/yeucauthanhtoan/edit/${value}`);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  }

  const { Panel } = Collapse;

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Yêu Cầu Thanh Toán</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id
            ? "Chỉnh Sửa Yêu Cầu Thanh Toán / "
            : "Tạo Yêu Cầu Thanh Toán Mới"}
          <Select
            placeholder="Chọn"
            bordered={false}
            size="small"
            onChange={(value) =>
              value ? setSearch(value) : setSearch("job_no")
            }
            defaultValue="job_no"
          >
            <Option value="job_no">Job No</Option>
            <Option value="cust_no">Customer No</Option>
            <Option value="cust_name">Customer Name</Option>
            <Option value="customs_no">Custom No</Option>
            <Option value="container_no">Container No</Option>
            <Option value="note">Note</Option>
            <Option value="bill_no">Bill No</Option>
            <Option value="document_staff">Staff</Option>
            <Option value="po_no">P/O</Option>
          </Select>
          <Input.Search
            size="small"
            defaultValue={id}
            bordered={false}
            style={{ width: 150 }}
            onSearch={onChange}
          />
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin size="large" spinning={isLoading}>
        <div style={{ float: "left", width: "10%" }}>
          <Table
            size="small"
            className="fix-table-list-job"
            dataSource={dataTest}
            columns={columnSearch}
            pagination={false}
            scroll={{ y: 680 }}
            rowKey={columnSearch[0].key.toString()}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  onChangeEdit(record.JOB_NO);
                },
              };
            }}
          />
        </div>
        <div style={{ float: "right", width: "89%" }}>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header={`Thông tin job ${id ? id : ""}`} key="1">
              <div
                className="site-layout-background"
                style={{ padding: 24, minHeight: 360 }}
              >
                <Form
                  name="dynamic_form_nest_item"
                  onFinish={onFinish}
                  autoComplete="off"
                  form={form}
                >
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={6}>
                      <Form.Item label="Job No" name="JOB_NO">
                        <Select
                          placeholder="Chọn Job"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={onCahngSelectJob}
                          onKeyDown={handleEnter}
                          size="small"
                        >
                          {dropdown.jobPaymentNotCreate.map((item, index) => {
                            return (
                              <Option key={index} value={item.JOB_NO}>
                                {item.JOB_NO}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Debit Date" name="DEBIT_DATE">
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label="Duyệt"
                        name="PAYMENT_CHK"
                        valuePropName="checked"
                      >
                        <Checkbox disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Approve Date" name="PAYMENT_DATE">
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={6}>
                      <Form.Item label="Customer No" name="CUST_NO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      <Form.Item name="CUST_NAME">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={12}>
                      <Form.Item label="Carriers" name="CUST_NO2">
                        <Select
                          placeholder="Chọn"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onKeyDown={handleEnter}
                          size="small"
                        >
                          {dropdown.carrier.map((item, index) => {
                            return (
                              <Option key={index} value={item.CUST_NO}>
                                {`${item.CUST_NO} | ${item.CUST_NAME}`}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Agent" name="CUST_NO3">
                        <Select
                          placeholder="Chọn"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onKeyDown={handleEnter}
                          size="small"
                        >
                          {dropdown.agent.map((item, index) => {
                            return (
                              <Option key={index} value={item.CUST_NO}>
                                {`${item.CUST_NO} | ${item.CUST_NAME}`}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={8}>
                      <Form.Item label="Consignee" name="CONSIGNEE">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Shipper" name="SHIPPER">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Bill No" name="BILL_NO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={8}>
                      <Form.Item label="Order From" name="ORDER_FROM">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Order To" name="ORDER_TO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Số tờ khai" name="CUSTOMS_NO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={8}>
                      <Form.Item label="Cust Date" name="CUSTOMS_DATE">
                        <Input type="date" onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Invoice No" name="INVOICE_NO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Container No" name="CONTAINER_NO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={6}>
                      <Form.Item label="Container Qty" name="CONTAINER_QTY">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="Qty" name="QTY">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="NW" name="NW">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="GW" name="GW">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label="POL" name="POL">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={8}>
                      <Form.Item label="POD" name="POD">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="ETD/ETA" name="ETD_ETA">
                        <Input type="date" onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="P/O No" name="PO_NO">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
                    <Col span={24}>
                      <Form.Item label="Ghi Chú" name="NOTE">
                        <Input onKeyDown={handleEnter} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "-1.5em" }}>
                    <Col>
                      <Form.Item>
                        <Button accessKey="s" type="primary" htmlType="submit">
                          {id ? "Lưu" : "Tạo mới"}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      {id ? (
                        <Button
                          type="primary"
                          style={{ marginLeft: 20 }}
                          onClick={() =>
                            history.push("/Dasboard/yeucauthanhtoan/create")
                          }
                        >
                          Tạo mới
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Panel>
          </Collapse>

          {id ? (
            <div
              className="site-layout-background"
              style={{ padding: 10, minHeight: 360 }}
            >
              <CreateItemYeuCauThanhToan
                job_d={job_d}
                id={id ? id : lender}
                getDataDetail={getDataDetail}
              />
            </div>
          ) : null}
        </div>
      </Spin>
    </Fragment>
  );
}
export default CreateYeuCauThanhToan;
