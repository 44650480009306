import React, { Fragment } from "react";
import Title from "antd/lib/typography/Title";
import { Button, Col, Form, Input, Row } from "antd";
import { FetchURL } from "../../../util/help";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//https://job-api.ihtvn.com/api/v1/export/file/lifting/fromdate=20201216&todate=20201216
export default function ThongKeNangHa() {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  function onFinish(values) {
    var from = undefined;
    var to = undefined;
    var yearFrom = values.fromdate.slice(0, 4);
    var monthFrom = values.fromdate.slice(5, 7);
    var dayFrom = values.fromdate.slice(8, 10);

    var yearTo = values.todate.slice(0, 4);
    var monthTo = values.todate.slice(5, 7);
    var dayTo = values.todate.slice(8, 10);

    from = yearFrom + monthFrom + dayFrom;
    to = yearTo + monthTo + dayTo;
    // window.open(FetchURL + `export/file/lifting/fromdate=${from}&todate=${to}`);
    // axios.get(`https://job-api.ihtvn.com/api/v1/export/file/lifting/fromdate=${from}&todate=${to}`).then(res=>console.log(res)).catch(err=>console.log(err))
    fetch(FetchURL + `export/file/lifting/fromdate=${from}&todate=${to}`)
      .then((res) => res.json())
      .then((data) => {
        var dataExcel = [];
        data.data.forEach((element) => {
          var value = {
            "Job No": element.JOB_NO,
            "Cust Name": element.CUST_NAME,
            "Cust Tax": element.CUST_TAX,
            "Bill No": element.BILL_NO,
            "Container No": element.CONTAINER_NO,
            "Container QTY": element.CONTAINER_QTY,
            "Mã Lô": "",
            "Mã DK": "",
            "Số Tiền": "",
          };
          dataExcel.push(value)
        });
        exportToCSV(dataExcel, 'Thống Kê Nâng Hạ')
      })
      .catch((err) => console.log(err));
  }
  return (
    <Fragment>
      <Title level={4} style={{ color: "#1890ff" }}>
        Thống kê nâng hạ
      </Title>
      <Form onFinish={onFinish}>
        <Row>
          <Col>
            <Form.Item label="Từ ngày" name="fromdate">
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Đến ngày"
              style={{ marginLeft: 20 }}
              name="todate"
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ marginLeft: 20 }}>
              <Button htmlType="submit" type="primary">
                Xuất file excel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}
