import { LIST_CHI_PHI_CUOC_CONT } from "../actions/actionTypes";

var initialState = [];

// const findIndex = (data, CUST_NO) => {
//   var result = -1;
//   data.forEach((customer, index) => {
//     if (customer.CUST_NO === CUST_NO) {
//       result = index;
//     }
//   });
//   return result;
// };

const boatfee = (state = initialState, action) => {
  //   var index = -1;
  switch (action.type) {
    case LIST_CHI_PHI_CUOC_CONT:
        state = action.boatfee;
      return [...state];
    default:
      return state;
  }
};

export default boatfee;
