import React, { useState, useEffect, Fragment } from "react";
import {
  Breadcrumb,
  Table,
  Input,
  Space,
  Button,
  Popconfirm,
} from "antd";
import Title from "antd/lib/typography/Title";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteStaff, fetchStaff } from "../../../actions/actionStaffs";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import axios from "../../../util/api";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  addFormData,
  FetchURL,
  openNotificationWithIcon,
} from "../../../util/help";

function Staffs() {
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });

  const staffs = useSelector((state) => state.staffs);
  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const getStaff = (data) => dispatch(fetchStaff(data));
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const DeleteStaff = (PNL_NO) => dispatch(deleteStaff(PNL_NO));

  useEffect(() => {
    showLoading();
    fetch(FetchURL + "data-basic/staff-customs")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          getStaff(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const Delete = (PNL_NO, PNL_NAME) => {
    const params = {
      PNL_NO: PNL_NO,
    };
    showLoading();
    axios
      .post("data-basic/staff-customs/remove", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            DeleteStaff(PNL_NO);
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã xóa thành công ${PNL_NAME}`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "PNL No",
      dataIndex: "PNL_NO",
      key: "PNL_NO",
      ...getColumnSearchProps("PNL_NO"),
    },
    {
      title: "Tên Nhân Viên",
      dataIndex: "PNL_NAME",
      key: "PNL_NAME",
      ...getColumnSearchProps("PNL_NAME"),
    },
    {
      title: "Số Điện Thoại",
      dataIndex: "PNL_TEL",
      key: "PNL_TEL",
    },
    {
      title: "Người Nhập",
      dataIndex: "INPUT_USER",
      key: "INPUT_USER",
    },
    {
      title: "Ngày Nhập",
      dataIndex: "INPUT_DT",
      key: "INPUT_DT",
    },
    {
      title: "Người Sửa",
      dataIndex: "MODIFY_USER",
      key: "MODIFY_USER",
    },
    {
      title: "Ngày Sửa",
      dataIndex: "MODIFY_DT",
      key: "MODIFY_DT",
    },
    {
      render: (text, record) => (
        <>
          <Link to={`/Dasboard/nhanvien/edit/${record.PNL_NO}`}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#faad14",
                borderColor: "#faad14",
                marginRight: 20,
              }}
              icon={<EditOutlined />}
            >
              <span style={{ marginLeft: 8, color: "white" }}>Sửa</span>
            </Button>
          </Link>

          <Popconfirm
            title="Bạn có chắn chắn xóa?"
            onConfirm={() => Delete(record.PNL_NO, record.PNL_NAME)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              Xóa
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Nhân Viên</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Nhân Viên</Title>
        </div>

        <Button type="primary" style={{ marginBottom: 20 }}>
          <Link to="/Dasboard/nhanvien/create">Tạo mới</Link>
        </Button>

        <Table
          dataSource={staffs}
          columns={columns}
          rowKey={columns[0].key.toString()}
          pagination={{ position: ["bottomLeft"] }}
          size='small'
          loading={isLoading}
        />
      </div>
      </Fragment>
  );
}

export default Staffs;
