import { DELETE_PHIEU_CHI_TAM_UNG, FETCH_PHIEU_CHI_TAM_UNG } from "./actionTypes";

export const fetchPhieuChi = (phieuchi) => {
  return {
    type: FETCH_PHIEU_CHI_TAM_UNG,
    phieuchi,
  };
};

export const deletePhieuChi = (JOB_NO) => {
  return {
    type: DELETE_PHIEU_CHI_TAM_UNG,
    JOB_NO,
  };
};
