import { DELETE_JOB_ORDER,  FETCH_JOB_ORDER } from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, JOB_NO) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.JOB_NO === JOB_NO) {
      result = index;
    }
  });
  return result;
};

const taojoborder = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_JOB_ORDER:
      state = action.job;
      return [...state];
    case DELETE_JOB_ORDER:
      index = findIndex(state, action.JOB_NO);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default taojoborder;
