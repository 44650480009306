import {
  FETCH_JOB_ORDER_PENDING,
  FETCH_JOB_ORDER_APPROVE,
  APPROVE_THANH_TOAN,
} from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, JOB_NO) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.JOB_NO === JOB_NO) {
      result = index;
    }
  });
  return result;
};

const duyetjoborder = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_JOB_ORDER_PENDING:
      state = action.pending;
      return state;
    case FETCH_JOB_ORDER_APPROVE:
      state = action.approve;
      return state;
    case APPROVE_THANH_TOAN:
      index = findIndex(state, action.JOB_NO);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default duyetjoborder;
