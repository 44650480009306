import { FETCH_CARRIER, DELETE_CARRIER } from "./actionTypes";

export const fetchCarrier = (carrier) => {
  return {
    type: FETCH_CARRIER,
    carrier,
  };
};

export const deleteCarrier = (CUST_NO) => {
  return {
    type: DELETE_CARRIER,
    CUST_NO,
  };
};
