import { DELETE_PHIEU_THU, FETCH_PHIEU_THU } from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, RECEIPT_NO) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.RECEIPT_NO === RECEIPT_NO) {
      result = index;
    }
  });
  return result;
};

const phieuthu = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_PHIEU_THU:
      state = action.phieuthu;
      return [...state];
    case DELETE_PHIEU_THU:
      index = findIndex(state, action.RECEIPT_NO);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default phieuthu;
