import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Spin,
  Table,
  Button,
  Popconfirm,
  Pagination,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useSelector, useDispatch } from "react-redux";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  openNotificationWithIcon,
} from "../../../util/help";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import axios from "../../../util/api";
import {
    approveThanhToan,
  fetchApproveJobOrder,
  fetchPendingJobOrder,
} from "../../../actions/actionDuỵetJobOrder";

const { TabPane } = Tabs;

function DuyetThoanToan() {
  const [state, setState] = useState({ total_page: 1 });

  const isLoading = useSelector((state) => state.isLoading);
  const duyetjoborder = useSelector((state) => state.duyetjoborder);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const Fetch = (data) => dispatch(fetchPendingJobOrder(data));
  const Fetch2 = (data) => dispatch(fetchApproveJobOrder(data));
  const changeApprove= (JOB_NO) => dispatch(approveThanhToan(JOB_NO))

  useEffect(() => {
    onChangePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePage = (page) => {
    var newPage = 1;
    if (page) {
      newPage = page;
    }
    showLoading();
    fetch(FetchURL + `payment/paid-debit/list-pending/page=${newPage}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const onChangePageApprove = (page) => {
    var newPage = 1;
    if (page) {
      newPage = page;
    }
    showLoading();
    fetch(FetchURL + `payment/paid-debit/list-paid/page=${newPage}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch2(data.data);
          setState({ total_page: data.total_page });
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  const Duyet = (JOB_NO, TYPE) => {
    const params ={
      TYPE: TYPE,
      JOB_NO: JOB_NO
    }
    showLoading();
    axios
      .post("payment/paid-debit/change", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            changeApprove(JOB_NO)
            openNotificationWithIcon("success", res.data.data);
            hideLoading();
          }
        }
      })
      .catch((err) => {
        hideLoading();
        console.log(err);
      });
  };

  const columns = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Customer Name",
      dataIndex: "CUST_NAME",
    },
    {
      title: "Debit Date",
      dataIndex: "DEBIT_DATE",
      render: (text) => convertDateTime(text),
    },
    {
      title: "From",
      dataIndex: "TRANS_FROM",
    },
    {
      title: "To",
      dataIndex: "TRANS_TO",
    },
    {
      title: "Payment Date",
      dataIndex: "PAYMENT_DATE",
      render: (text) => convertDateTime(text),
    },
    {
      render: (text, record) => (
        <>
          <Popconfirm
            title="Bạn có chắn chắn?"
            onConfirm={() => {
              Duyet(record.JOB_NO, record.PAYMENT_DATE ? '2' : '1' );
            }}
          >
            <Button type="primary">
              {record.PAYMENT_DATE ? "Bỏ duyệt" : "Duyệt"}
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Duyệt Thanh Toán</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Duyệt Thanh Toán</Title>
        </div>

        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"large"}
          onTabClick={(e) =>
            e === "2" ? onChangePageApprove() : onChangePage()
          }
        >
          <TabPane tab="Pending" key="1">
            <Table
              dataSource={duyetjoborder}
              columns={columns}
              rowKey={columns[0].key.toString()}
              pagination={false}
              size='small'
            />
            <Pagination
              total={state.total_page}
              defaultCurrent={1}
              onChange={onChangePage}
              style={{ marginTop: 20 }}
            />
          </TabPane>
          <TabPane tab="Approve" key="2">
            <Table
              dataSource={duyetjoborder}
              columns={columns}
              rowKey={columns[0].key.toString()}
              pagination={false}
              size='small'
            />{" "}
            <Pagination
              total={state.total_page}
              defaultCurrent={1}
              onChange={onChangePageApprove}
              style={{ marginTop: 20 }}
            />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
}

export default DuyetThoanToan;
