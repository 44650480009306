import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Spin,
  Table,
  Input,
  Space,
  Button,
  Popconfirm,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
  openNotificationWithIcon,
  FetchURL,
  addFormData,
} from "../../../util/help";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../util/api";
import { deleteUser, fetchUsers } from "../../../actions/actionUsers";

var user_no =   localStorage.getItem("USER_NO")

function Users() {
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });

  const isLoading = useSelector((state) => state.isLoading);
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const DeleteUser = (USER_NO) => dispatch(deleteUser(USER_NO));
  const FetchUsers = (data) => dispatch(fetchUsers(data));

  useEffect(() => {
    showLoading();
    fetch(FetchURL + "system/user")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          FetchUsers(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const Delete = (USER_NO) => {
    showLoading();
    const params = {
      USER_NO: USER_NO,
    };
    axios
      .post("system/user/remove", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            DeleteUser(USER_NO);
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã xóa thành công ${USER_NO}`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Tên đăng nhập",
      dataIndex: "USER_NO",
      key: "USER_NO",
      ...getColumnSearchProps("USER_NO"),
    },
    {
      title: "Tên người dùng",
      dataIndex: "USER_NAME",
      key: "USER_NAME",
      ...getColumnSearchProps("USER_NAME"),
    },
    {
      render: (text, record) => (
        <>
          <Link to={`/Dasboard/users/edit/${record.USER_NO}`}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#faad14",
                borderColor: "#faad14",
                marginRight: 20,
              }}
              icon={<EditOutlined />}
            >
              <span style={{ marginLeft: 8, color: "white" }}>Sửa</span>
            </Button>
          </Link>

          <Popconfirm
            title="Bạn có chắn chắn xóa?"
            onConfirm={() => Delete(record.USER_NO)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              Xóa
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
user_no === 'PC                  ' ?
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Tài Khoản Người Dùng</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Tài Khoản Người Dùng</Title>
        </div>

        <Button type="primary" style={{ marginBottom: 20 }}>
          <Link to="/Dasboard/users/create">Tạo mới</Link>
        </Button>

        <Table
          dataSource={users}
          columns={columns}
          rowKey={columns[0].key.toString()}
          pagination={{ position: ["bottomLeft"] }}
        />
      </div>
    </Spin> : <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      ><div style={{ textAlign: "center" }}>
      <Title style={{ color: "#1890ff" }}>Bạn không có quyền truy cập</Title>
    </div></div>
  );
}

export default Users;
