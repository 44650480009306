import axios from "axios";

const API_URL =
  "https://hp-api.ihtvn.com/api/v1/";

axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Accept = "application/json";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
