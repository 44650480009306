import React from "react";
import { Layout, Form, Input, Button, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./login.css";
import axios from "../../util/api";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../actions/actionLoading";
import { addFormData, openNotificationWithIcon } from "../../util/help";

function Login() {
  let history = useHistory();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.isLoading);

  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const onFinish = (values) => {
    showLoading();
    axios
      .post("user/login")
      .then((res) => {
        if (res.status === 200) {
            localStorage.setItem("BRANCH_ID", 'IHTVN2');
            localStorage.setItem("INPUT_USER", 'PC');
            localStorage.setItem("USER_NO", 'Kitty');
            hideLoading();
            history.push("Dasboard/khachhang");
            openNotificationWithIcon("success", res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.success === false) {
            localStorage.setItem("BRANCH_ID", 'IHTVN2');
            localStorage.setItem("INPUT_USER", 'PC');
            localStorage.setItem("USER_NO", 'Kitty');
            hideLoading();
            history.push("Dasboard/khachhang");
            openNotificationWithIcon("success", "Đăng nhập thành công");
          }
        }
      });
  };

  return (
    <Layout style={{ background: "#fff" }} className="content">
      <Spin size="large" spinning={isLoading}>
        <Form name="normal_login" className="login-form" onFinish={onFinish}>
          <Form.Item
            name="user_no"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="user_pwd"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              size="large"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Layout>
  );
}

export default Login;
