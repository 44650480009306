import React, { useEffect } from "react";
import { Breadcrumb, Divider } from "antd";
import Title from "antd/lib/typography/Title";
import { useDispatch } from "react-redux";
import {
  actDropdownCustomer,
  actDropdownJobOrder,
  actJobPhieuChiTamUng,
} from "../../../actions/actionDropdown";
import InPhieuTheoDoi from "./InPhieuTheoDoi";
import InJobOrder from "./InJobOrder";
import InRefund from "./InRefund";
import ThongKeJob from "./ThongKeJob";
import ThongKeNangHa from "./ThongKeNangHa";
import { checkUserBD } from "../../../util/help";
import ThongKeJobStart from "./ThongKeJobStart";

function BaoBieuHoSo() {
  const dispatch = useDispatch();
  const FetchJob = () => dispatch(actJobPhieuChiTamUng());
  const FetchCustomer = () => dispatch(actDropdownCustomer());
  const FetchJobOrder = () => dispatch(actDropdownJobOrder());

  let user = localStorage.getItem("USER_NO");

  useEffect(() => {
    FetchJob();
    FetchCustomer();
    FetchJobOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Hồ Sơ</Breadcrumb.Item>
        <Breadcrumb.Item>Báo Biểu Hồ Sơ</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Báo Biểu Hồ Sơ</Title>
        </div>

        <InPhieuTheoDoi />
        <Divider />
        <ThongKeJobStart />
        <Divider />
        <InJobOrder />
        <Divider />
        {checkUserBD.indexOf(user.toString()) === -1 ? (
          <>
            <InRefund />
            <Divider />
            <ThongKeJob />
            <Divider />
            <ThongKeNangHa />
          </>
        ) : null}
      </div>
    </>
  );
}

export default BaoBieuHoSo;
