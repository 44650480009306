import { Col, Form, Row, Select, Input, Button } from "antd";
import React from "react";

const { Option } = Select;

function SearchComponent({ onSearch, name }) {
  function onFinish(values) {
    onSearch(values);
  }

  function renderSearch(name) {
    switch (name) {
      case (name = "phieuchitamung"):
        return (
          <Select placeholder="Chọn" style={{ width: 200 }}>
            <Option value="job_no">Job No</Option>
            <Option value="lender_no">Advance No</Option>
            <Option value="pnl_no">Mã Nhân Viên</Option>
            <Option value="pnl_name">Tên Nhân Viên</Option>
          </Select>
        );
      case (name = "joborder"):
        return (
          <Select placeholder="Chọn" style={{ width: 200 }}>
            <Option value="job_no">Job No</Option>
            <Option value="cust_no">Customer No</Option>
            <Option value="cust_name">Customer Name</Option>
            <Option value="customs_no">Custom No</Option>
            <Option value="container_no">Container No</Option>
            <Option value="note">Note</Option>
            <Option value="bill_no">Bill No</Option>
            <Option value="document_staff">Staff</Option>
            <Option value="po_no">P/O</Option>
          </Select>
        );
      case (name = "phieuthu"):
        return (
          <Select placeholder="Chọn" style={{ width: 200 }}>
            <Option value="job_no">Job No</Option>
            <Option value="receipt_no">Receipt No</Option>
            <Option value="cust_no">Customer</Option>
          </Select>
        );
      case (name = "customer"):
        return (
          <Select placeholder="Chọn" style={{ width: 200 }}>
            <Option value="no">No</Option>
            <Option value="name">Name</Option>
          </Select>
        );
      default:
        return (
          <Select placeholder="Chọn" style={{ width: 200 }}>
            <Option value="job_no">Job No</Option>
            <Option value="container_no">Container</Option>
            <Option value="cust_no">Customer No</Option>
            <Option value="customs_no">Custom No</Option>
            <Option value="po_no">P/O</Option>
          </Select>
        );
    }
  }
  return (
    <Form onFinish={onFinish}>
      <Row gutter={24} style={{ padding: 10 }}>
        <Col span={4} />
        <Col className="gutter-row" span={4}>
          <Form.Item name="type" initialValue="job_no">
            {renderSearch(name)}
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={8}>
          <Form.Item name="keyword">
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col className="gutter-row" span={4}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Tìm (Search)
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default SearchComponent;
