import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumb, Form, Input, Spin, Select, Row, Col, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  actDropdownCustomer,
  actDropdownStaff,
  actJobPhieuChiTamUng,
} from "../../../../actions/actionDropdown";
import {
  addFormData,
  convertDateTime,
  FetchURL,
  openNotificationWithIcon,
} from "../../../../util/help";
import CreatItemPhieuChiTamUng from "./CreatItemPhieuChiTamUng";
import {
  actHideLoading,
  actShowLoading,
} from "../../../../actions/actionLoading";
import axios from "../../../../util/api";
import HotKeys from "react-hot-keys";

const { Option } = Select;

function CreatePhieuChiTamUng() {
  let { id } = useParams();
  let history = useHistory();
  const [form] = Form.useForm();

  const [job_d, setJob_d] = useState([]);
  const [lender, setLender] = useState("");
  const [dataDrop, setDataDrop] = useState([])

  const isLoading = useSelector((state) => state.isLoading);
  const dropdown = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const getDropDownCustomer = () => dispatch(actDropdownCustomer());
  const getDropDownStaff = () => dispatch(actDropdownStaff());

  const setField = (data) => {
    form.setFieldsValue({
      JOB_NO: data.JOB_NO,
      LENDER_TYPE: data.LENDER_TYPE,
      CUST_NAME: data.CUST_NAME,
      CUST_NO: data.CUST_NO,
      ORDER_FROM: data.ORDER_FROM,
      ORDER_TO: data.ORDER_TO,
      CONTAINER_QTY: data.CONTAINER_QTY,
      PNL_NO: data.PNL_NO,
      DOR_NO: data.DOR_NO,
      LEND_REASON: data.LEND_REASON,
      LENDER_NO: data.LENDER_NO,
      LENDER_DATE: convertDateTime(data.LENDER_DATE),
      QTY: data.QTY,
    });
  };

  const printPhieuChi = (id) => {
    window.open(FetchURL + `print/payment/advance/advance_no=${id}`);
  };

  const getDataDetail = (id) => {
    fetch(FetchURL + `payment/lender/des/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setJob_d(data.lenderD);
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDropDownCustomer();
    getDropDownStaff();
    // getDropDownListJobPhieuChi();
    if (id) {
      showLoading();
      getDataDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    showLoading();
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    if (id) {
      values.LENDER_NO = id;
      values.MODIFY_USER = localStorage.getItem("USER_NO");

      axios.post("payment/lender/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            // history.goBack();
          } else {
            hideLoading();
            openNotificationWithIcon("error", "Job đã tồn tại");
          }
        }
      });
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios.post("payment/lender/add", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            setLender(res.data.data.LENDER_NO);
            hideLoading();
            history.push(
              `/Dasboard/phieuchitamung/edit/${res.data.data.LENDER_NO}`
            );
            //   history.goBack();
          }
        }
      });
    }
  };

  const onCahngSelectJob = (values) => {
    fetch(FetchURL + `file/job-start/des/${values}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };

  function onKeyUp(keyName, e, handle) {
    if (e) {
      e.preventDefault();
    }
    var values = form.getFieldsValue();
    onFinish(values);
  }

  const onSearch = (input) => {
    fetch(FetchURL + `file/job-start/search/type=job_no&value=${input ? input : '22'}&page=1`)
      .then((res) => res.json())
      .then((data) => {
        setDataDrop(data.data)
      });
  };

  // const options = this.state.data.map(d => <Option key={d.value}>{d.text}</Option>)

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Phiếu Chi Tạm Ứng</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Chỉnh Sửa Phiếu Chi Tạm Ứng" : "Tạo Phiếu Chi Tạm Ứng Mới"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Spin size="large" spinning={isLoading}>
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}
        >
          <HotKeys keyName="ctrl+s" onKeyDown={onKeyUp}>
            <Form
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item label="Job No" name="JOB_NO">
                    <Select
                      showSearch
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={(input) => {
                        setTimeout(onSearch(input), 5000);
                      }}
                      onChange={onCahngSelectJob}
                    >{dataDrop.map((item, index) => {
                      return (
                        <Option key={index} value={item.JOB_NO}>
                          {item.JOB_NO}
                        </Option>
                      );
                    })}</Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="Type"
                    name="LENDER_TYPE"
                    rules={[{ required: true, message: "Vui lòng chọn type" }]}
                  >
                    <Select placeholder="Chọn">
                      <Option value="U">Phieu Tam Ung</Option>
                      <Option value="T">Chi Tam Ung</Option>
                      <Option value="C">Chi Truc Tiep</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Advance No" name="LENDER_NO">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Date" name="LENDER_DATE">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label="Advance Staff"
                    name="PNL_NO"
                    rules={[
                      { required: true, message: "Vui lòng chọn nhân viên" },
                    ]}
                  >
                    <Select
                      placeholder="Chọn"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={onChange}
                    >
                      {dropdown.staff.map((item, index) => {
                        return (
                          <Option key={item.PNL_NO}>
                            {item.PNL_NO + " " + item.PNL_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Kinds Of Money"
                    name="DOR_NO"
                    rules={[
                      { required: true, message: "Vui lòng chọn loại tiền" },
                    ]}
                  >
                    <Select placeholder="Chọn">
                      <Option value="VND">VND</Option>
                      <Option value="USD">USD</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Customer No" name="CUST_NO">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="" name="CUST_NAME">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={10}>
                  <Form.Item label="Order From" name="ORDER_FROM">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} />

                <Col span={10}>
                  <Form.Item label="Order To" name="ORDER_TO">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={10}>
                  <Form.Item label="Container Qty" name="CONTAINER_QTY">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={4} />
                <Col span={10}>
                  <Form.Item label="Qty" name="QTY">
                    <Input
                      disabled
                      style={{ color: "red", fontWeight: "bold" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item label="Reasons" name="LEND_REASON">
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item style={{ textAlign: "right" }}>
                    <Button type="primary" htmlType="submit">
                      {id ? "Lưu" : "Tạo mới"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <div style={{ marginLeft: 20 }}>
                    {id ? (
                      <>
                        <Button
                          type="primary"
                          onClick={() => printPhieuChi(id)}
                        >
                          In
                        </Button>
                        <Button
                          style={{ marginLeft: 20 }}
                          type="primary"
                          onClick={() =>
                            history.push("/Dasboard/phieuchitamung/create")
                          }
                        >
                          Tạo mới
                        </Button>
                      </>
                    ) : lender ? (
                      <>
                        <Button
                          type="primary"
                          onClick={() => printPhieuChi(lender)}
                        >
                          In
                        </Button>
                        <Button
                          style={{ marginLeft: 20 }}
                          type="primary"
                          onClick={() =>
                            history.push("/Dasboard/phieuchitamung/create")
                          }
                        >
                          Tạo mới
                        </Button>
                      </>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Form>
          </HotKeys>
        </div>

        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360, marginTop: 40 }}
        >
          {id ? (
            <CreatItemPhieuChiTamUng
              job_d={job_d}
              id={id ? id : lender}
              getDataDetail={getDataDetail}
              saveDetail={onKeyUp}
            />
          ) : null}
        </div>
      </Spin>
    </Fragment>
  );
}
export default CreatePhieuChiTamUng;
