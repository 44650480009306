import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Select,
  Button,
  Spin,
  AutoComplete,
  Row,
  Col,
  Typography,
} from "antd";
import "./tableEdit.css";
import { useSelector, useDispatch } from "react-redux";
import {
  actHideLoading,
  actShowLoading,
} from "../../../../actions/actionLoading";
import axios from "../../../../util/api";
import {
  addFormData,
  convertNumber,
  handleEnter,
  onKeyDown,
  openNotificationWithIcon,
} from "../../../../util/help";
import { actDropdownTypeCosts } from "../../../../actions/actionDropdown";

const { Option } = Select;
const { Text } = Typography;

const CreateItemYeuCauThanhToan = ({ job_d, id, getDataDetail }) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ data: [] });
  const [serNo, setSerNo] = useState("");

  const isLoading = useSelector((state) => state.isLoading);
  const dropdown = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const typeCosts = () => dispatch(actDropdownTypeCosts());

  //focus input
  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus];
  };
  const [inputTien, setInputTien] = UseFocus();

  useEffect(() => {
    typeCosts();
    var newData = job_d;
    for (var i = 0; i < newData.length; i++) {
      newData[i].key = i;
    }
    setState({ ...state, data: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_d]);

  const edit = (record) => {
    form.setFieldsValue({
      DEB_TYPE: record.DEB_TYPE,
      DESCRIPTION: record.DESCRIPTION,
      INV_NO: record.INV_NO,
      UNIT: record.UNIT,
      DOR_NO: record.DOR_NO,
      DOR_RATE: record.DOR_RATE,
      QUANTITY: record.QUANTITY,
      PRICE: record.PRICE,
      TAX_NOTE: record.TAX_NOTE,
      DOR_AMT: record.DOR_AMT,
      TAX_AMT: record.TAX_AMT,
      TOTAL_AMT: record.TOTAL_AMT,
    });
    setSerNo(record.SER_NO);
  };

  const setField = () => {
    form.setFieldsValue({
      DEB_TYPE: "",
      DESCRIPTION: "",
      INV_NO: "",
      UNIT: "",
      DOR_NO: "VND",
      DOR_RATE: 0,
      QUANTITY: "",
      PRICE: "",
      TAX_NOTE: 0,
      DOR_AMT: 0,
      TAX_AMT: 0,
      TOTAL_AMT: 0,
    });
    setSerNo("");
  };

  const getDataField = (record) => {
    var tong = 0;
    var dorNo = form.getFieldsValue().DOR_NO;
    var qty = form.getFieldsValue().QUANTITY;
    var price = form.getFieldsValue().PRICE;
    var taxNote = form.getFieldsValue().TAX_NOTE;
    var priceDor = form.getFieldsValue().DOR_AMT;
    var rate = form.getFieldsValue().DOR_RATE;
    if (dorNo === "VND") {
      tong = qty * price + qty * price * (taxNote / 100);
      form.setFieldsValue({
        TOTAL_AMT: tong,
      });
    } else {
      tong =
        qty * (priceDor * rate) + qty * (priceDor * rate) * (taxNote / 100);
      form.setFieldsValue({
        PRICE: priceDor * rate,
        TOTAL_AMT: tong,
      });
    }
  };

  const Delete = async (key) => {
    try {
      const newData = [...state.data];
      const index = newData.findIndex((item) => key.key === item.key);

      if (index > -1) {
        const params = {
          JOB_NO: id,
          SER_NO: newData[index].SER_NO,
        };
        showLoading();
        axios
          .post("payment/debit-note/remove-d", addFormData(params))
          .then((res) => {
            if (res.status === 200) {
              if (res.data.success === true) {
                newData.splice(index, 1);
                setState({ ...state, data: newData });
                hideLoading();
                openNotificationWithIcon("success", `Bạn đã xoá thành công`);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Loại",
      dataIndex: "DEB_TYPE",
      width: 250,
      editable: true,
    },
    {
      title: "Invoice No",
      dataIndex: "INV_NO",
      width: 150,
      editable: true,
    },
    {
      title: "Mô tả",
      dataIndex: "DESCRIPTION_NAME_VN",
      width: 250,
      editable: true,
    },
    {
      title: "UNIT",
      dataIndex: "UNIT",
      editable: true,
    },
    {
      title: "Currency",
      dataIndex: "DOR_NO",
      editable: true,
    },
    {
      title: "Price",
      dataIndex: "DOR_AMT",
      editable: true,
      render: (text) => convertNumber(parseFloat(text)),
    },
    {
      title: "Rate",
      dataIndex: "DOR_RATE",
      editable: true,
      render: (text) => convertNumber(parseFloat(text)),
    },
    {
      title: "QTY",
      dataIndex: "QUANTITY",
      editable: true,
      render: (text) => convertNumber(parseFloat(text)),
    },
    {
      title: "Price (VND)",
      dataIndex: "PRICE",
      editable: true,
      render: (text) => convertNumber(parseFloat(text)),
    },
    {
      title: "Tax",
      dataIndex: "TAX_NOTE",
      editable: true,
      render: (text) => convertNumber(parseFloat(text)),
    },
    {
      title: "Total",
      dataIndex: "TOTAL_AMT",
      editable: true,
      render: (_, record) => {
        return convertNumber(
          parseFloat(
            record.PRICE * record.QUANTITY +
              ((record.PRICE * record.TAX_NOTE) / 100) * record.QUANTITY
          )
        );
      },
    },
    {
      title: "Action",
      width: 150,
      dataIndex: "operation",
      render: (_, record) => {
        return (
          <>
            <Button type="link" onClick={() => edit(record)}>
              Edit
            </Button>

            <Popconfirm
              title="Bạn có chắn chắn xóa?"
              onConfirm={() => Delete(record)}
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const options = dropdown.costs.map((item) => {
    return { label: item.DESCRIPTION_NAME_VN, value: item.DESCRIPTION_NAME_VN };
  });

  function onFinish(values) {
    values.QUANTITY
      ? (values.QUANTITY = values.QUANTITY)
      : (values.QUANTITY = 0);
    values.PRICE ? (values.PRICE = values.PRICE) : (values.PRICE = 0);
    values.JOB_NO = id;
    values.INV_YN = "N";
    values.TAX_AMT = values.TOTAL_AMT - values.PRICE * values.QUANTITY;
    showLoading();
    if (serNo) {
      values.SER_NO = serNo;
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      axios
        .post("payment/debit-note/edit-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              setField();
              hideLoading();
              openNotificationWithIcon("success", "Chỉnh Sửa Thành Công");
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
      values.INPUT_USER = localStorage.getItem("USER_NO");

      axios
        .post("payment/debit-note/add-d", addFormData(values))
        .then((res) => {
          if (res.status === 200) {
            if (res.data.success === true) {
              getDataDetail(id);
              setField();
              hideLoading();
              openNotificationWithIcon("success", "Tạo Thành Công");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function onKeyUp(value) {
    if (value.key === "Enter" && value.keyCode === 13) {
      form.submit();
      setInputTien();
    }
  }

  return (
    <Fragment>
      <Form onFinish={onFinish} onKeyDown={onKeyDown} form={form}>
        <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
          <Col>
            <Form.Item label="Loại" name="DEB_TYPE">
              <Select
                size="small"
                style={{ width: 180 }}
                ref={inputTien}
                showSearch
                onKeyDown={handleEnter}
              >
                <Option key="Our Company Pay">Our Company Pay</Option>
                <Option key="Pay In Advance">Pay In Advance</Option>
                <Option key="Trucking Fee">Trucking Fee</Option>
                <Option key="Deposit fee">Cược Cont</Option>
                <Option key="Deposit fix fee">Cược Sửa Chữa Cont</Option>
                <Option key="Refund Customer">Refund Khách Hàng</Option>
                <Option key="Refund Carriers">Refund Hãng Tàu</Option>
                <Option key="Refund Agent">Refund Đại Lý</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Invoice No"
              style={{ marginLeft: 10 }}
              name="INV_NO"
            >
              <Input
                style={{ width: 150 }}
                size="small"
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Mô tả" name="DESCRIPTION">
              <AutoComplete
                options={options}
                style={{ width: 200 }}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                size="small"
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="UNIT" name="UNIT">
              <Input
                style={{ width: 100 }}
                size="small"
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Currency" name="DOR_NO" initialValue="VND">
              <Select
                onChange={getDataField}
                style={{ width: 80 }}
                size="small"
                onKeyDown={handleEnter}
              >
                <Option key="VND">VND</Option>
                <Option key="USD">USD</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} style={{ marginBottom: "-1.5em" }}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.DOR_NO !== currentValues.DOR_NO
            }
          >
            {({ getFieldValue }) => {
              return getFieldValue("DOR_NO") === "USD" ? (
                <>
                  <Col>
                    <Form.Item label="Price" name="DOR_AMT">
                      <InputNumber
                        onChange={getDataField}
                        size="small"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: 120 }}
                        onKeyDown={handleEnter}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Rate" name="DOR_RATE">
                      <InputNumber
                        onChange={getDataField}
                        size="small"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        style={{ width: 120 }}
                        onKeyDown={handleEnter}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null;
            }}
          </Form.Item>
          <Col>
            <Form.Item label="QTY" name="QUANTITY">
              <InputNumber
                onChange={getDataField}
                size="small"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: 120 }}
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Price VND" name="PRICE">
              <InputNumber
                onChange={getDataField}
                size="small"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: 120 }}
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Tax" name="TAX_NOTE" initialValue="0">
              <Select
                onChange={getDataField}
                style={{ width: 70 }}
                onChange={getDataField}
                size="small"
                onKeyDown={handleEnter}
              >
                <Option key="0">0</Option>
                <Option key="8">8</Option>
                <Option key="10">10</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Total" name="TOTAL_AMT" initialValue="0">
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={getDataField}
                style={{ width: 120 }}
                size="small"
                onKeyDown={handleEnter}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            accessKey="s"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 20 }}
            onKeyUp={onKeyUp}
          >
            {serNo ? "Lưu" : "Thêm"}
          </Button>
          {serNo ? (
            <Button type="link" onClick={setField}>
              Cancel
            </Button>
          ) : null}
        </Form.Item>
      </Form>

      <Spin size="large" spinning={isLoading}>
        <Table
          dataSource={state.data}
          columns={columns}
          bordered
          pagination={false}
          size="small"
          summary={(pageData) => {
            var total = 0;
            console.log(pageData);
            pageData.forEach(({ TOTAL_AMT, QUANTITY, PRICE, TAX_NOTE }) => {
              var money =
                PRICE * QUANTITY + ((PRICE * TAX_NOTE) / 100) * QUANTITY;
              total += parseFloat(money);
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell>
                  <Text type="danger">{convertNumber(total)}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Spin>
    </Fragment>
  );
};

export default CreateItemYeuCauThanhToan;
