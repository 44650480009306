import React, { useEffect } from "react";
import { Breadcrumb, Form, Input, Button, Spin } from "antd";
import axios from "../../../util/api";
import { useDispatch, useSelector } from "react-redux";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useHistory, useParams } from "react-router-dom";
import { addFormData, FetchURL } from "../../../util/help";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

function CreateCarrier() {
  let history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();

  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());

  const setField = (data) => {
    form.setFieldsValue({
      CUST_NO: data.CUST_NO,
      CUST_NAME: data.CUST_NAME,
      CUST_CNAME: data.CUST_CNAME,
      TEN_DON_VI: data.TEN_DON_VI,
      CUST_ADDRESS: data.CUST_ADDRESS,
      CUST_TEL1: data.CUST_TEL1,
      CUST_TEL2: data.CUST_TEL2,
      CUST_FAX: data.CUST_FAX,
      CUST_TAX: data.CUST_TAX,
      CUST_BOSS: data.CUST_BOSS,
    });
  };

  useEffect(() => {
    if (id) {
      showLoading();
      fetch(FetchURL + `data-basic/customer/des/id=${id}/type=2`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          setField(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    values.CUST_TYPE = 2;
    showLoading();
    if (id) {
      values.MODIFY_USER = localStorage.getItem("USER_NO");
      axios.post("data-basic/customer/edit", addFormData(values)).then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    } else {
      values.INPUT_USER = localStorage.getItem("USER_NO");
      axios.post("data-basic/customer/add", addFormData(values)).then((res) => {
        if (res.status === 201) {
          if (res.data.success === true) {
            hideLoading();
            history.goBack();
          }
        }
      });
    }
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Hãng Tàu</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Chỉnh Sửa Hãng Tàu" : "Tạo Hãng Tàu Mới"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Form
            {...layout}
            form={form}
            name="nest-messages"
            onFinish={onFinish}
          >
            {id ? (
              <Form.Item label="CUST_NO" name="CUST_NO" hidden>
                <Input />
              </Form.Item>
            ) : null}

            <Form.Item label="Tên hãng tàu" name="CUST_NAME">
              <Input />
            </Form.Item>
            <Form.Item label="Tên hãng tàu 2" name="CUST_CNAME">
              <Input />
            </Form.Item>
            <Form.Item label="Tên đơn vị" name="TEN_DON_VI">
              <Input />
            </Form.Item>
            <Form.Item label="Địa chỉ" name="CUST_ADDRESS">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label="Điện thoại 1" name="CUST_TEL1">
              <Input />
            </Form.Item>
            <Form.Item label="Điện thoại 2" name="CUST_TEL2">
              <Input />
            </Form.Item>
            <Form.Item label="Số fax" name="CUST_FAX">
              <Input />
            </Form.Item>
            <Form.Item label="Mã số thuế" name="CUST_TAX">
              <Input />
            </Form.Item>
            <Form.Item label="Người liên hệ" name="CUST_BOSS">
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                {id ? "Sửa" : "Tạo mới"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}
export default CreateCarrier;
