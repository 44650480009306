import { DELETE_TYPECOST, FETCH_TYPECOST } from "./actionTypes";

export const fetchTypeCost = (typeCost) => {
  return {
    type: FETCH_TYPECOST,
    typeCost,
  };
};

export const deleteTypeCost = (DESCRIPTION_CODE) => {
  return {
    type: DELETE_TYPECOST,
    DESCRIPTION_CODE,
  };
};
