import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Spin,
  Table,
  Button,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import { openNotificationWithIcon, FetchURL, addFormData } from "../../../util/help";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../util/api";
import { deleteCarrier, fetchCarrier } from "../../../actions/actionCarriers";
import SearchComponent from "../../../Component/SearchComponent";

function Carriers() {
  const [search, setSearch] = useState({ type: "", keyword: "" });

  const carriers = useSelector((state) => state.carriers);
  const isLoading = useSelector((state) => state.isLoading);

  const dispatch = useDispatch();
  const getCarrier = (data) => dispatch(fetchCarrier(data));
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const DeleteCarrier = (CUST_NO) =>
    dispatch(deleteCarrier(CUST_NO));

  useEffect(() => {
    onChangePage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.keyword]);

  const onChangePage = (page) => {
    var newPage = 1;
    var url = "";
    if (page) {
      newPage = page;
    }
    if (search.keyword) {
      url = `data-basic/customer/search/group=carriers&type=${search.type}&value=${search.keyword}&page=${newPage}`;
    } else {
      url = `data-basic/customer/type=2`;
    }
    showLoading();
    fetch(FetchURL + url)
      .then((res) => res.json())
      .then((data) => {
        console.log(data.data)
        if (data.success === true) {
          getCarrier(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
  };
  
  function onSearch(values) {
    setSearch({
      type: values.type,
      keyword: values.keyword,
    });
  }


  const Delete = (CUST_NO, CUST_NAME) => {
    showLoading();
    const params ={
      CUST_NO: CUST_NO,
      CUST_TYPE: 2
    }
    axios
      .post("data-basic/customer/remove", addFormData(params))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.success === true) {
            DeleteCarrier(CUST_NO);
            hideLoading();
            openNotificationWithIcon(
              "success",
              `Bạn đã xóa thành công ${CUST_NAME}`
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "CUST NO",
      dataIndex: "CUST_NO",
      key: "CUST_NO",
    },
    {
      title: "Tên Công Ty",
      dataIndex: "CUST_NAME",
      key: "CUST_NAME",
    },
    {
      title: "Địa Chỉ",
      dataIndex: "CUST_ADDRESS",
      key: "CUST_ADDRESS",
    },
    {
      render: (text, record) => (
        <>
          <Link to={`/Dasboard/hangtau/edit/${record.CUST_NO}`}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#faad14",
                borderColor: "#faad14",
                marginRight: 20,
              }}
              icon={<EditOutlined />}
            >
              <span style={{ marginLeft: 8, color: "white" }}>Sửa</span>
            </Button>
          </Link>

          <Popconfirm
            title="Bạn có chắn chắn xóa?"
            onConfirm={() => Delete(record.CUST_NO, record.CUST_NAME)}
          >
            <Button type="primary" danger icon={<DeleteOutlined />}>
              Xóa
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dữ Liệu Cơ Bản</Breadcrumb.Item>
        <Breadcrumb.Item>Hãng Tàu</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Hãng Tàu</Title>
        </div>
        <SearchComponent name='customer' onSearch={onSearch}/>
        <Button type="primary" style={{ marginBottom: 20 }}>
          <Link to="/Dasboard/hangtau/create">Tạo mới</Link>
        </Button>

        <Table
          dataSource={carriers}
          columns={columns}
          rowKey={columns[0].key.toString()}
          pagination={{ position: ["bottomLeft"] }}
        />
      </div>
    </Spin>
  );
}

export default Carriers;
