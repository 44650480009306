import { DELETE_USER, FETCH_USERS } from "./actionTypes";

export const fetchUsers = (data) => {
  return {
    type: FETCH_USERS,
    data,
  };
};

export const deleteUser = (USER_NO) => {
  return {
    type: DELETE_USER,
    USER_NO,
  };
};
