import { notification } from "antd";
import * as XLSX from "xlsx";

export const openNotificationWithIcon = (type, message, value) => {
  notification[type]({
    message: message,
    description: value,
  });
};

export function handleEnter(event) {
  if (event.keyCode === 13) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
}

export const convertDateTime = (DateTime) => {
  var a = DateTime;
  var b = a
    ? [a.slice(0, 4), "-", a.slice(4, 6), "-", a.slice(6, 8)].join("")
    : null;
  return b;
};

export const FetchURL = "https://hp-api.ihtvn.com/api/v1/";

export const payDescription = [
  "Thêm",
  "DEPOSIT CONTAINER",
  "BẤM SEAL",
  "BĂNG KEO",
  "BIÊN PHÒNG",
  "BỐC XẾP",
  "CẮT SEAL",
  "CHUYỂN TK",
  "COST OF PNH",
  "CPF",
  "Customs fee",
  "ĐĂNG KÍ ĐỊNH MỨC",
  "ĐĂNG KÍ PHỤ KIỆN",
  "danh muc",
  "ĐỐI CHIẾU LỆNH",
  "ĐÓNG DẤU BIÊN BẢN",
  "GHI PHIẾU KHO",
  "GIÁM SÁT",
  "GỬI CHUYỂN PHÁT NHANH",
  "HẢI QUAN KHO/BÃI",
  "KIỂM DỊCH",
  "KIỂM HÓA",
  "KIỂM TRA HSƠ",
  "LỆ PHÍ BÃI",
  "LE PHI HQ",
  "MO TK",
  "Over time fee ",
  "PERMIT FEE",
  "PHÍ BÃI",
  "PHI KHAI O MY PHUOC",
  "PHU THU LE PHI",
  "PROFIT SHARE TO PNH",
  "RÚT TK",
  "SERVICE CUSTOMS CLEARANCE",
  "THANH LÍ CỔNG",
  "THANH LÍ TK",
  "THC",
  "THỰC XUẤT",
  "TIỀN XE BUÝT",
  "TÍNH GIÁ THUẾ",
  "UNLOADING FEE",
  "VÀO SỔ HỢP ĐỒNG",
  "XE NÂNG",
  "Cam control fee",
  "Clear cont fee",
  "D/O",
  "OCEAN FREIGHT",
  "PHÍ BỐC XẾP",
  "PHÍ CHUYỂN BÃI",
  "PHÍ CƯỢC CONT",
  "PHÍ HẠ",
  "PHÍ LƯU CONT",
  "PHÍ NÂNG",
  "THC",
  "VỆ SINH CONT",
  "TRUCKING FEE",
];

export const orderType = [
  { type: "I", name: "Our Company Pay" },
  { type: "O", name: "Pay In Advance" },
  { type: "T", name: "Trucking Fee" },
  { type: "C", name: "Deposit fee" },
  { type: "8", name: "Deposit fix fee" },
  { type: "6", name: "Refund Customer" },
  { type: "5", name: "Refund Carriers" },
  { type: "7", name: "Refund Agent" },
];

export const convertNumber = (number) => {
  var newNumber = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return newNumber;
  // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
};

export const onKeyDown = (e) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export const addFormData = (values) => {
  var fomrData = new FormData();
  for (var key in values) {
    fomrData.append(key, values[key]);
  }
  return fomrData;
};

export const checkUserBD = [
  "BD1                 ",
  "BD2                 ",
  "BD3                 ",
  "BD4                 ",
  "BD5                 ",
  "BD6                 ",
  "BD7                 ",
];

export function formatColumn(ws, colNum, range, fmt) {
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = XLSX.utils.encode_cell({ r: row, c: colNum });
    if (ws[ref] && ws[ref].t === "n") {
      ws[ref].z = fmt;
    }
  }
}
