//Loading
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";

//Customer
export const FETCH_CUSTOMER = "FETCH_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DETAIL_CUSTOMER = "DETAIL_CUSTOMER";

export const CLEAR_DETAIL = "CLEAR_DETAIL";

//Staffs
export const FETCH_STAFF = "FETCH_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";

//TypeCost
export const FETCH_TYPECOST = "FETCH_TYPECOST";
export const DELETE_TYPECOST = "DELETE_TYPECOST";

//Carrier
export const FETCH_CARRIER = "FETCH_CARRIER";
export const DELETE_CARRIER = "DELETE_CARRIER";

//Dropdown
export const DROP_DOWN_CUSTOMER = "DROP_DOWN_CUSTOMER";
export const DROP_DOWN_STAFF = "DROP_DOWN_STAFF";
export const DROP_DOWN_LIST_NOT_CREATED_ORDER =
  "DROP_DOWN_LIST_NOT_CREATED_ORDER";
export const DROP_DOWN_LIST_JOB_PHIEU_CHI = "DROP_DOWN_LIST_JOB_PHIEU_CHI";
export const DROP_DOWN_LIST_PAYMENT_NOT_CREATE =
  "DROP_DOWN_LIST_PAYMENT_NOT_CREATE";
export const DROP_DOWN_CARRIER = "DROP_DOWN_CARRIER";
export const DROP_DOWN_AGENT = "DROP_DOWN_AGENT";
export const DROP_DOWN_TYPE_COSTS = "DROP_DOWN_TYPE_COSTS";
export const DROP_DOWN_JOB_ORDER = "DROP_DOWN_JOB_ORDER";
export const LIST_OF_CUSTOMER = "LIST_OF_CUSTOMER";
export const DROP_DOWN_INFORMATION_USER = "DROP_DOWN_INFOMATION_USER";
export const DROP_DOWN_PAYMENT_DEBIT = "DROP_DOWN_PAYMENT_DEBIT";
export const DROP_DOWN_LIST_PHIEU_CHI = "DROP_DOWN_LIST_PHIEU_CHI";
export const DROP_DOWN_LIST_JOB_HAS_D = "DROP_DOWN_LIST_JOB_HAS_D";

//Tao phieu theo doi
export const FETCH_JOB_START = "FETCH_JOB_START";
export const DELETE_JOB_START = "DELETE_JOB_START";

//Tao Job Order
export const FETCH_JOB_ORDER = "FETCH_JOB_ORDER";
export const DELETE_JOB_ORDER = "DELETE_JOB_ORDER";
export const CREATE_JOB_ORDER = 'CREATE_JOB_ORDER'

//Phieu Chi Tam Ung
export const FETCH_PHIEU_CHI_TAM_UNG = "FETCH_PHIEU_CHI_TAM_UNG";
export const DELETE_PHIEU_CHI_TAM_UNG = "DELETE_PHIEU_CHI_TAM_UNG";

//Yeu cau thanh toan
export const FETCH_YEU_CAU_THANH_TOAN = "FETCH_YEU_CAU_THANH_TOAN";
export const DELETE_YEU_CAU_THANH_TOAN = "DELETE_YEU_CAU_THANH_TOAN";

//Duyệt Job Order
export const FETCH_JOB_ORDER_PENDING = "FETCH_JOB_ORDER_PENDING";
export const FETCH_JOB_ORDER_APPROVE = "FETCH_JOB_ORDER_APPROVE";

//Xoa thanh toan
export const APPROVE_THANH_TOAN = "APPROVE_THANH_TOAN";

//List Chi Phí Cược Cont
export const LIST_CHI_PHI_CUOC_CONT = "LIST_CHI_PHI_CUOC_CONT";

//Phieu thu
export const FETCH_PHIEU_THU = "FETCH_PHIEU_THU";
export const DELETE_PHIEU_THU = "DELETE_PHIEU_THU";

//Người dùng
export const FETCH_USERS = "FETCH_USERS";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";
export const DES_USER = "DES_USER";
