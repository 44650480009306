import React, { useEffect } from "react";
import {
  Breadcrumb,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Table,
  Button,
} from "antd";
import Title from "antd/lib/typography/Title";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDesBoatFee } from "../../../actions/actionPaymentBoatFee";
import { actHideLoading, actShowLoading } from "../../../actions/actionLoading";
import {
  addFormData,
  convertDateTime,
  convertNumber,
  FetchURL,
} from "../../../util/help";
import { actDropdownCustomer } from "../../../actions/actionDropdown";
import axios from "../../../util/api";

function ChiPhiCuocTauContEdit() {
  let { id, month } = useParams();
  const [form] = Form.useForm();

  const isLoading = useSelector((state) => state.isLoading);
  const boatfee = useSelector((state) => state.boatfee);
  const dropdown = useSelector((state) => state.dropdown);

  const dispatch = useDispatch();
  const Fetch = (data) => dispatch(fetchDesBoatFee(data));
  const showLoading = () => dispatch(actShowLoading());
  const hideLoading = () => dispatch(actHideLoading());
  const customerFetch = () => dispatch(actDropdownCustomer());

  useEffect(() => {
    customerFetch();
    showLoading();
    fetch(FetchURL + `payment/boat-fee/des-month/type=${id}&value=${month}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          Fetch(data.data);
          hideLoading();
        }
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Job No",
      dataIndex: "JOB_NO",
      key: "JOB_NO",
    },
    {
      title: "Boat Leave Date",
      dataIndex: "BOAT_LEAVE_DATE",
      key: "BOAT_LEAVE_DATE",
      render: (text) => convertDateTime(text),
    },
    {
      title: "Pay Date",
      dataIndex: "PAY_DATE",
      key: "PAY_DATE",
      render: (text) => convertDateTime(text),
    },
    {
      title: "Pay Note",
      dataIndex: "PAY_NOTE",
      key: "PAY_NOTE",
    },
    {
      title: "Mã Khách Hàng",
      dataIndex: "CUST_NO",
      key: "CUST_NO",
    },
    {
      title: "Order From",
      dataIndex: "ORDER_FROM",
      key: "ORDER_FROM",
    },
    {
      title: "Order To",
      dataIndex: "ORDER_TO",
      key: "ORDER_TO",
    },
    {
      title: "VND FEE",
      dataIndex: "VND_FEE",
      key: "VND_FEE",
      render: (text) => (text ? convertNumber(parseFloat(text)) : null),
    },
    {
      title: "USD FEE",
      dataIndex: "USD_FEE",
      key: "USD_FEE",
      render: (text) => (text ? convertNumber(parseFloat(text)) : null),
    },
  ];

  function editCell(values) {
    console.log(values);
    form.setFieldsValue({
      JOB_NO: values.JOB_NO,
      BOAT_LEAVE_DATE: convertDateTime(values.BOAT_LEAVE_DATE),
      PAY_DATE: convertDateTime(values.PAY_DATE),
      PAY_NOTE: values.PAY_NOTE,
      CUST_NO: values.CUST_NO,
      ORDER_FROM: values.ORDER_FROM,
      ORDER_TO: values.ORDER_TO,
      VND_FEE: values.VND_FEE,
      USD_FEE: values.USD_FEE,
    });
  }

  function onFinish(values) {
    console.log(values);
    values.FEE_TYPE = id;
    values.BRANCH_ID = localStorage.getItem("BRANCH_ID");
    values.PAID_VND_FEE = values.VND_FEE;
    values.PAID_USD_FEE = values.USD_FEE;
    values.REAL_PAY_DATE = values.PAY_DATE;
    showLoading();
    axios
      .post("payment/boat-fee/edit", addFormData(values))
      .then((res) => {
        if (res.status === 200) {
          fetch(
            FetchURL + `payment/boat-fee/des-month/type=${id}&value=${month}`
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success === true) {
                Fetch(data.data);
                hideLoading();
              }
            });
        }
      })
      .catch((err) => console.log(err));
  }

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Quản Lý Thu Chi</Breadcrumb.Item>
        <Breadcrumb.Item>Thêm Chi Phí Cược Cont</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div style={{ textAlign: "center" }}>
          <Title style={{ color: "#1890ff" }}>Thêm Chi Phí Cược Cont</Title>
        </div>
        <Form
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          onKeyDown={onKeyDown}
        >
          <Row>
            <Col>
              <Form.Item label="Job No" name="JOB_NO">
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Boat Leave Date"
                name="BOAT_LEAVE_DATE"
                style={{ marginLeft: 10 }}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Pay Date"
                name="PAY_DATE"
                style={{ marginLeft: 10 }}
              >
                <Input type="date" />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="Pay Note"
                name="PAY_NOTE"
                style={{ marginLeft: 10 }}
              >
                <Input.TextArea style={{ height: "32px" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Mã Khách Hàng" name="CUST_NO">
                <Select style={{ width: 430 }}>
                  {dropdown.customer.map((item) => {
                    return (
                      <Select.Option key={item.CUST_NO}>
                        {item.CUST_NO + " | " + item.CUST_NAME}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label=" Order From"
                name="ORDER_FROM"
                style={{ marginLeft: 10 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="OrderTo"
                name="ORDER_TO"
                style={{ marginLeft: 10 }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="VND FEE"
                name="VND_FEE"
                style={{ marginLeft: 10 }}
              >
                <InputNumber
                  style={{ width: 150 }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label="USD FEE"
                name="USD_FEE"
                style={{ marginLeft: 10 }}
              >
                <InputNumber
                  style={{ width: 150 }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Form.Item name="BOAT_FEE_MONTH" style={{ marginLeft: 10 }} hidden>
              <Input />
            </Form.Item>
            <Col>
              <Form.Item>
                <Button
                  accessKey="s"
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: 20 }}
                >
                  Thêm
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          dataSource={boatfee}
          columns={columns}
          rowKey={columns[0].key.toString()}
          size="small"
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                editCell(record);
              }, // click row
            };
          }}
        />
      </div>
    </Spin>
  );
}

export default ChiPhiCuocTauContEdit;
